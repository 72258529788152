import { API } from "./documentBaseService";
import { StorageHelper } from "app/core/storageHelper";

export class DocumentService {
  static contextPath = "/api/v1/";

  static doGetDocType = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}documenttype`);
  };
  static doGetDocTmfLevel = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}documenttmflevel`);
  };
  static doGetDocHistory = (id, lastmodifiedon, versionId) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    let requestParam = `documentId=${id}`;
    if (lastmodifiedon)
      requestParam = `${requestParam}&lastmodifiedon=${lastmodifiedon}&documentVersionGuid=${versionId}`;
    return API.get(`${contextPath}document/getdocumenthistory?${requestParam}`);
  };
  static doGetReviewActionDetail = (id, versionGuid) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(
      `${contextPath}document/getrevieweractiondetail?documentId=${id}&documentVersionGuid=${versionGuid}`
    );
  };
  static doGetReviewerAction = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/getrevieweractionmaster`);
  };
  static doGetDocumentMaster = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/documentmasters`);
  };
  static doSaverevieweraction = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.post(`${contextPath}document/saverevieweraction`, data);
  };
  static doGetDocumentGuid = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/GuidCreation`);
  };
  static doGetDropZoneDocumentGuid = (filesCount) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/GuidsCreation?numberOfGuid=${filesCount}`);
  };
  static docPublishDocDetails = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}document/documentpublishfromunpublish`, data);
  };

  static docUnPublishDocDetails = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(
      `${contextPath}document/documentunpublishfromunpublish`,
      data
    );
  };
  static docApproveDoc = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(
      `${contextPath}document/documentapprovefromawaitreview`,
      data
    );
  };

  static tmfStructureDataService = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}zone/list`);
  };

  static docUpdateChanges = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}document/updatedocumenteditchanges`, data);
  };
  static docFlaggedByQc = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(
      `${contextPath}document/documentapprovefromflaggedbyqc`,
      data
    );
  };

  static doDocumentDelete = (payload) => {
    const { id, reason, lastModifiedOn, documentversionguid } = payload;
    const appSettings = StorageHelper.getAppSettings();
    let documentDeletePath = `${appSettings.baseDocumentUrl}/api/v1/document/delete?documentId=${id}&documentVersionGuid=${documentversionguid}&lastModifiedOn=${lastModifiedOn}`;
    if (reason) documentDeletePath = `${documentDeletePath}&comments=${reason}`;
    return API.delete(documentDeletePath);
  };

  static getFooterText = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}/viedocclient/footer`);
  };

  static doDocumentLock = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}document/lock`, data);
  };

  static doFileScreening = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.post(`${contextPath}filescreeningstatus`, data);
  };
  static getMileStoneCount = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}milestones/count`);
  };
  static getMileStoneList = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}milestones/milestones`);
  };
  static getMileStoneData = (id) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}milestones/milestones?milestoneId=${id}`);
  };
  static getMileStoneArtifactCount = (id) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}milestones/artifacts?milestoneId=${id}`);
  };
  static getMileStoneGroup = () => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}milestones/groups`);
  };
  static addNewMileStone = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.post(`${contextPath}milestones`, data);
  };
  static doEditMileStone = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}milestones`, data);
  };
  static changeMileStoneSequence = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}milestones/moveup`, data);
  };
  static doDeleteMileStone = (data) => {
    const { id, lastModifiedOn } = data;
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/milestones?id=${id}&lastmodifiedon=${lastModifiedOn}`;
    return API.delete(contextPath);
  };
  static doGetDocumentVersionList = (id) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/getdocumentversionsbydocid?documentId=${id}`);
  }
  static getSelectedDocumentDetail = (id) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/getdocumentdetailsbydocumentversionid?documentVersionId=${id}`);
  }
  static addDocNote = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.post(`${contextPath}document/addnotes`, data);
  };
  static getDocNote = (versionId, dropzoneId) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.get(`${contextPath}document/getnotesbydocumentversiondropzoneid?documentVersionId=${versionId}&tmfdropzoneid=${dropzoneId}`);
  };
  static resolveDocNote = (data) => {
    const appSettings = StorageHelper.getAppSettings();
    const contextPath = `${appSettings.baseDocumentUrl}/api/v1/`;
    return API.put(`${contextPath}document/resolvenotesbynoteid`, data);
  };
}
