import * as actionTypes from "app/store/actions/actionTypes";

export const fetchDocMaster = (isNewDocVersionCreated) => ({
  type: actionTypes.FETCH_DOCUMENT_MASTER,
  isNewDocVersionCreated
});
export const fetchDocMasterFailed = () => ({
  type: `${actionTypes.FETCH_DOCUMENT_MASTER}_FAILED`,
});
export const fetchDocMasterSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_MASTER}_SUCCESS`,
  payload,
});

export const documentStatusChange = (payload, category, searchview, isNewDocVersionCreated) => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE,
  payload,
  category,
  searchview,
  isNewDocVersionCreated
});
export const documentStatusChangeFailed = () => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE_FAILED,
});
export const documentStatusChangeSuccess = (payload) => ({
  type: actionTypes.DOCUMENT_STATUS_CHANGE_SUCCESS,
  payload,
});

export const docAddReviewComment = (payload) => ({
  type: actionTypes.ADD_REVIEW_COMMENT,
  payload,
});
export const docEmptyReviewComment = () => ({
  type: actionTypes.EMPTY_REVIEW_COMMENT,
});

export const deleteDocument = (payload, isDialog = false, isDeleteDialog = false, searchView = false) => ({
  type: actionTypes.FETCH_DOCUMENT_DELETE,
  payload,
  isDialog,
  isDeleteDialog,
  searchView
});
export const deleteDocumentSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_DELETE}_SUCCESS`,
  payload,
});
export const deleteDocumentFailed = () => ({
  type: `${actionTypes.FETCH_DOCUMENT_DELETE}_FAILED`,
});

export const fetchDocumentLock = (payload, searchview) => ({
  type: actionTypes.FETCH_DOCUMENT_LOCK,
  payload,
  searchview
});
export const fetchDocumentLockFailed = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_LOCK}_FAILED`,
  payload,
});
export const fetchDocumentLockSuccess = (payload) => ({
  type: `${actionTypes.FETCH_DOCUMENT_LOCK}_SUCCESS`,
  payload,
});
export const doGetDocumentVersionList = (payload) => ({
  type: actionTypes.GET_DOCUMENT_VERSION_LIST,
  payload
});
export const doGetDocumentVersionListSuccess = (payload) => ({
  type: `${actionTypes.GET_DOCUMENT_VERSION_LIST}_SUCCESS`,
  payload
});
export const doGetDocumentVersionListFailed = (payload) => ({
  type: `${actionTypes.GET_DOCUMENT_VERSION_LIST}_FAILED`,
  payload,
});

export const getSelectedDocumentDetail = (payload) => ({
  type: actionTypes.GET_SELECTED_DOCUMENT_DETAIL,
  payload
});
export const getSelectedDocumentDetailSuccess = (payload) => ({
  type: `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_SUCCESS`,
  payload
});
export const getSelectedDocumentDetailFailed = (payload) => ({
  type: `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_FAILED`,
  payload,
});
export const resetViewDialog = (payload) => ({
  type: `${actionTypes.RESET_VIEW_DIALOG}`,
  payload
});
export const docClearNote = (payload) => ({
  type: actionTypes.CLEAR_DOC_NOTE,
  payload,
});
export const addDocNote = (payload, artId) => ({
  type: actionTypes.ADD_DOC_NOTE,
  payload,
  artId
});
export const addDocNoteSuccess = (payload) => ({
  type: `${actionTypes.ADD_DOC_NOTE}_SUCCESS`,
  payload
});
export const addDocNoteFailed = (payload) => ({
  type: `${actionTypes.ADD_DOC_NOTE}_FAILED`,
  payload,
});
export const getDocNote = (payload, dropzoneId) => ({
  type: actionTypes.GET_DOC_NOTE,
  payload,
  dropzoneId
});
export const getDocNoteSuccess = (payload) => ({
  type: `${actionTypes.GET_DOC_NOTE}_SUCCESS`,
  payload
});
export const getDocNoteFailed = (payload) => ({
  type: `${actionTypes.GET_DOC_NOTE}_FAILED`,
  payload,
});
export const resolveDocNote = (payload, artId) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}`,
  payload,
  artId
});
export const resolveDocNoteSuccess = (payload) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}_SUCCESS`,
  payload,
});
export const resolveDocNoteFailed = (payload) => ({
  type: `${actionTypes.RESOLVE_DOC_NOTE}_FAILED`,
  payload,
});
export const setPreviousVersionId = (payload) => ({
  type: `${actionTypes.PRE_VERSION_ID}`,
  payload,
});
export const errorWithDateValue = (payload) => ({
  type: actionTypes.ERROR_WITH_DATE_VALUE,
  payload,
});