import { put, takeEvery, delay, takeLatest } from "redux-saga/effects";
import * as actionTypes from "app/store/actions/actionTypes";
import * as actions from "app/store/actions/zoneActions";
import * as actionsLoader from "app/store/actions/loaderActions";
import { ZoneService } from "app/secure/services/zoneService";
import { UtilsHelper } from "app/core/utilsHelper";
import { StorageHelper } from "app/core/storageHelper";
import i18n from "i18nnext";
import APP_CONST from "app/core/constant";
import { DocumentService } from "app/secure/services/documentService";
import _ from "underscore";
import * as templateActions from "app/store/actions/templateActions";

export function* getDocumentsBySectionSaga(data) {
    try {
        const response = yield ZoneService.doGetDocumentsBySection(data.payload);
        const { isError, result } = response.data;
        if (!isError) {
            StorageHelper.setFilteredData(result);
            yield put(actions.getDocumentsBySectionSuccess(result));
            if (!_.isEmpty(result.documentDetails)) {
                const sharedData = result.documentDetails.filter((share) => share.artifacturl !== "");
                yield put(templateActions.getSharedDocumentSuccess(sharedData));
            }
        } else {
            yield put(actionsLoader.showError(response.statusText));
            yield put(actions.getDocumentsBySectionFailed());
        }
        if (data.isNewDocVersionCreated) {
            yield put(actionsLoader.hideLoader());
        }
    } catch (e) {
        yield put(actions.getDocumentsBySectionFailed());
        yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
}

export function* getDocumentsByArtifactSaga(data) {
    try {
        const response = yield ZoneService.doGetDocumentsByArtifact(data.payload);
        const { isError, result } = response.data;
        if (!isError) {
            yield put(actions.getDocumentsBySectionSuccess(result, data.payload));
            const existData = JSON.parse(StorageHelper.getCurrentDocument());
            let documentIds = [];
            if (!_.isEmpty(existData)) {
                existData.map((id) => {
                    documentIds.push(id.documentguid);
                })
            }
            if (!_.isEmpty(result.documentDetails)) {
                let sessionData = [];
                result.documentDetails.map((latest) => {
                    if (documentIds.includes(latest.documentguid)) {
                        sessionData.push(latest);
                    }
                })
                Array.prototype.push.apply(sessionData, existData);
                StorageHelper.setCurrentDocument(sessionData);
            }
            if (!_.isEmpty(result.documentDetails)) {
                const sharedData = result.documentDetails.filter((share) => share.artifacturl !== "");
                yield put(templateActions.getSharedDocumentSuccess(sharedData));
            }
        } else {
            yield put(actionsLoader.showError(response.statusText));
            yield put(actions.getDocumentsBySectionFailed(data.payload));
        }
    } catch (e) {
        yield put(actions.getDocumentsBySectionFailed(data.payload));
        yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
}

export function* fetchAllClientApiSaga() {
    try {
        const eTMF_Info = JSON.parse(sessionStorage.getItem("token_info"));

        const getViedocInfo = yield ZoneService.doGetViedocInfo();
        if (getViedocInfo.status === 200) {
            const result = getViedocInfo.data.result;
            const orgInfo = result.orgInfo.orgGuid;
            const studyInfo = result.studyInfo;
            const userInfo = result.userInfo;
            const users = result.users;
            const usersRole = result.usersRole;

            yield put(actions.getRoleListSuccess(usersRole));
            yield put(actions.getStudyInfoSuccess(studyInfo));
            StorageHelper.clearRecentSearchInfo();
            yield put(actions.goToBrowserView());
            yield put(actions.getUserInfoSuccess(userInfo));
            yield put(actions.getUserListSuccess(users));
            yield put(actions.getOrgId(orgInfo));
        } else {
            yield put(actionsLoader.showError(getViedocInfo.statusText));
        }

        const apiInitConfigResponse = yield ZoneService.doGetAppInitConfig();
        if (apiInitConfigResponse.status === 200) {
            const appConfigResult = apiInitConfigResponse.data;
            yield put(actions.getAppInitConfigSuccess(appConfigResult.result));
        } else {
            yield put(actionsLoader.showError(apiInitConfigResponse.statusText));
        }


        const countryResponse = yield ZoneService.doGetCountryList();
        if (!countryResponse.data.isError) {
            const countryList = {
                dropDownName: "country",
                result: countryResponse.data.result,
            };
            yield put(actions.getCountrySiteListSuccess(countryList));
        } else {
            yield put(actionsLoader.showError(countryResponse.statusText));
        }

        const siteResponse = yield ZoneService.doGetSiteList();
        if (siteResponse.status === 200) {
            const siteList = {
                dropDownName: "site",
                result: siteResponse.data,
            };
            yield put(actions.getCountrySiteListSuccess(siteList));
        } else {
            yield put(actionsLoader.showError(siteResponse.statusText));
        }

    } catch (e) {
        yield put(actions.fetchAllClientApisFailed());
    }
}

export function* fetchSearchApiSaga(data) {
    try {
        const { payload } = data;
        yield put(actions.enableAggridIcon(false));
        const response = yield ZoneService.doGetSearchResult(payload);
        if (response.status === 200) {
            yield put(actions.fetchSearchResultSuccess(response.data.result));
            yield put(actions.enableAggridIcon(true));
            sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
        } else {
            const errorMessage = UtilsHelper.constructErrorMsg(response.data);
            yield put(actionsLoader.showError(errorMessage));
            yield put(actions.enableAggridIcon(true));
            yield put(actions.fetchSearchResultFailure());
            sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
        }
    } catch (e) {
        sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
        yield put(actions.fetchSearchResultFailure());
        yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
}


export function* fetchAllAGgridDataApiSaga(data) {
    try {
        const { payload, initLoad, refreshPage } = data;
        let isDetailPage = StorageHelper.getDetailView();
        let searchView = false;
        if (isDetailPage == "true" && initLoad === false) {
            searchView = true;
        }
        if (refreshPage) {
            yield put(actions.enableAggridIcon(false));
        }
        const response = yield ZoneService.doGetSearchResult(payload);
        if (response.status === 200) {
            yield put(actions.saveAllAgData(response.data.result));
            const recentPageInfo = StorageHelper.getRecentSearchInfo();
            let latestPage = StorageHelper.getDetailView();
            if (latestPage == "true") {
                if (!_.isEmpty(recentPageInfo)) {
                    StorageHelper.setRecentSearchInfo(recentPageInfo);
                }
                if (!response.data.result.uselocalstorage) {
                    yield put(actions.fetchSearchResultSuccess(response.data.result, true));
                }
            }
            sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
            yield put(actions.enableAggridIcon(true));
        } else {
            const errorMessage = UtilsHelper.constructErrorMsg(response.data);
            yield put(actionsLoader.showError(errorMessage));
            yield put(actions.fetchAllAggridDataFailure());
            yield put(actions.enableAggridIcon(true));
            sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
        }
        if (data.isNewDocVersionCreated) {
            yield put(actionsLoader.hideLoader());
        }
    } catch (e) {
        sessionStorage.setItem("IS_AGGRID_API_RUNNING", false);
        yield put(actions.fetchAllAggridDataFailure());
        yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
}


export function* fetcheTMFMetricsData() {
    const filterInfo = yield StorageHelper.getFilterInfo();
    const defaultValue = "ALL";
    const payload = {
        siteID: filterInfo
            ? filterInfo.selectedSiteID || defaultValue
            : defaultValue,
        countryID: filterInfo
            ? filterInfo.selectedCountryID || defaultValue
            : defaultValue,
    };
    let mileStoneObj = {
        milestoneGroup: "",
        milestoneId: "",
    };
    if (APP_CONST.MILESTONE_GROUP_LIST.includes(filterInfo.selectedMilestoneID)) {
        mileStoneObj = {
            milestoneGroup: filterInfo
                ? filterInfo.selectedMilestoneID || defaultValue
                : defaultValue,
            milestoneId: "",
        };
    } else {
        mileStoneObj = {
            milestoneGroup: "",
            milestoneId: filterInfo
                ? filterInfo.selectedMilestoneID || defaultValue
                : defaultValue,
        };
    }
    try {
        const response = yield ZoneService.doGetMetricsData({
            ...payload,
            ...mileStoneObj,
        });
        const { result, isError } = response.data;
        if (!isError) {
            const metricData = yield UtilsHelper.constructMetricsInfo(result);
            yield put(actions.fetchMetricsDataSuccess(metricData));
        }
    } catch (error) {
        yield put(actions.fetchMetricsDataFailed());
    }
}
export function* watchZoneAsync() {
    yield takeEvery(
        actionTypes.GET_DOCUMENY_BY_SECTION,
        getDocumentsBySectionSaga
    );
    yield takeEvery(
        actionTypes.GET_DOCUMENY_BY_ARTIFACT,
        getDocumentsByArtifactSaga
    );
    yield takeEvery(actionTypes.FETCH_ALL_CLIENT_APIS, fetchAllClientApiSaga);
    yield takeEvery(actionTypes.FETCH_SEARCH_RESULT, fetchSearchApiSaga);
    yield takeEvery(actionTypes.FETCH_METRICS_DATA, fetcheTMFMetricsData);
    yield takeLatest(actionTypes.FETCH_ALL_AGGRID_DATA, fetchAllAGgridDataApiSaga);

}
