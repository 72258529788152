import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import APP_CONST from "app/core/constant";
import _ from "underscore";

const initialState = {
  docType: [],
  tmfLevel: [],
  reviewerAction: [],
  commentListArr: [],
  docItemData: {},
  deleteDocumentInfo: {
    guid: "",
  },
  deletedDocumentGuid: "",
  lockDocumentChange: {},
  isDeleteLoading: false,
  resetViewDialog: true,
  noteArr: [],
  previousVersionId: "",
  isArtifactCall: false,
  noteModifiedDate: "",
  isValidDateValue: true
};

export const documentReducer = (
  state = initialState,
  action = { type: "" }
) => {
  switch (action.type) {
    case `${actionTypes.FETCH_DOCUMENT_MASTER}_SUCCESS`:
      const { documentType, documentLevels, reviewerActionMasterDto } =
        action.payload;
      const docType = documentType || [];
      const tmfLevel = documentLevels || APP_CONST.TMF_LEVELS;

      let reviewerAction = reviewerActionMasterDto || [];

      return updateObject(state, { docType, tmfLevel, reviewerAction });
    case `${actionTypes.FETCH_DOCUMENT_MASTER}_FAILED`:
      return updateObject(state, { docMaster: {} });
    case actionTypes.DOCUMENT_STATUS_CHANGE_SUCCESS:
      return updateObject(state, { docItemData: action.payload });
    case actionTypes.DOCUMENT_STATUS_CHANGE_FAILED:
      return updateObject(state, { docItemData: {} });
    case actionTypes.ADD_REVIEW_COMMENT:
      const updateCommentListArr = [...state.commentListArr];
      updateCommentListArr.push(action.payload);
      return updateObject(state, {
        commentListArr: updateCommentListArr,
      });
    case actionTypes.EMPTY_REVIEW_COMMENT:
      return updateObject(state, { commentListArr: [] });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}`:
      return updateObject(state, {
        deleteDocumentInfo: { guid: "" },
        isDeleteLoading: true,
      });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}_SUCCESS`:
      return updateObject(state, {
        deleteDocumentInfo: action.payload,
        deletedDocumentGuid: action.payload.documentguid,
        isDeleteLoading: false,
      });
    case `${actionTypes.FETCH_DOCUMENT_DELETE}_FAILED`:
      return updateObject(state, {
        deleteDocumentInfo: { guid: "" },
        isDeleteLoading: false,
      });

    case `${actionTypes.FETCH_DOCUMENT_LOCK}`:
      return updateObject(state, {});
    case `${actionTypes.FETCH_DOCUMENT_LOCK}_SUCCESS`:
      const updateDocumentPayload = {
        ...(action.payload.documentDetails || {}),
        ...action.payload,
      };
      const documentDetails = { documentDetails: { ...updateDocumentPayload } };
      const lockDocumentObj = {
        documentcontents: JSON.stringify(documentDetails),
      };
      return updateObject(state, {
        docItemData: lockDocumentObj,
        lockDocumentChange: updateDocumentPayload,
      });
    case `${actionTypes.FETCH_DOCUMENT_LOCK}_FAILED`:
      return updateObject(state, {});

    case `${actionTypes.GET_DOCUMENT_VERSION_LIST}_SUCCESS`:
      let versionList = action.payload;
      if (!_.isEmpty(versionList)) {
        versionList.map((version, index) => {
          versionList[index] = { value: version.documentversionnumber, label: version.documentversionnumber, ...version }
        })
      }
      return updateObject(state, { documentVersionList: versionList });

    case actionTypes.GET_SELECTED_DOCUMENT_DETAIL:
      return updateObject(state, { versionLoader: true });
    case `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_SUCCESS`:
      return updateObject(state, { selectedDocDetail: action.payload, versionLoader: false });
    case `${actionTypes.GET_SELECTED_DOCUMENT_DETAIL}_FAILED`:
      return updateObject(state, { versionLoader: false });
    case `${actionTypes.RESET_VIEW_DIALOG}`:
      return updateObject(state, { resetViewDialog: action.payload });
    case actionTypes.ADD_DOC_NOTE:
      return updateObject(state, {});
    case `${actionTypes.ADD_DOC_NOTE}_SUCCESS`:
      let notesList = action.payload.documentNotesDetails;
      let noteChangedDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteArr: notesList,
        noteModifiedDate: noteChangedDate,
        isArtifactCall: true
      });
    case `${actionTypes.GET_DOC_NOTE}_SUCCESS`:
      let notesDetail = action.payload.documentNotesDetails;
      let noteDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteArr: notesDetail,
        noteModifiedDate: noteDate,
      });

    case `${actionTypes.RESOLVE_DOC_NOTE}_SUCCESS`:
      let noteResolvedDate = action.payload.lastmodifiedon;
      return updateObject(state, {
        noteModifiedDate: noteResolvedDate,
        isArtifactCall: true
      });

    case actionTypes.ERROR_WITH_DATE_VALUE:
      return updateObject(state, {
        isValidDateValue: action.payload
      });
    case actionTypes.CLEAR_DOC_NOTE:
      return updateObject(state, {
        noteArr: [],
        isArtifactCall: false
      });
    case actionTypes.PRE_VERSION_ID:
      return updateObject(state, {
        previousVersionId: action.payload,
      });
    default:
      return state;
  }
};
