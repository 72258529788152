import moment from "moment";
import APP_CONST from "app/core/constant";
import { DateTime } from "luxon";
import { StorageHelper } from "app/core/storageHelper";
import AlertHelper from "app/core/alertHelper";
import i18n from "i18nnext";
import _ from "underscore";
import remove from "lodash/remove";
import { InputGroup } from "@blueprintjs/core";

export class UtilsHelper {
  static getFileExtension = (filename) => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1].toLowerCase();
  };
  static getFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  static getRandomNumbers = () => {
    return Math.floor(Math.random() * 100 + 1);
  };

  static getCurrentDateTime = (dateSeparator = "-", timeSeperator = ":") => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();

    return `${year}${dateSeparator}${month < 10 ? `0${month}` : `${month}`
      }${dateSeparator}${date} ${hour}${timeSeperator}${minute}`;
  };

  static localDateConversion = (date) => {
    let filterDate = date.split(".")[0];
    if (date.includes("Z")) filterDate = date.substring(0, date.length - 1);
    let newdate = new Date(filterDate);
    newdate = new Date(`${newdate} UTC`);
    const addZero = (val) => (val < 10 ? `0${val}` : val);
    if (isNaN(newdate.getTime())) {
      newdate = "";
    } else {
      let hours = newdate.getHours();
      let minutes = newdate.getMinutes();
      const strTime = addZero(hours) + ":" + addZero(minutes);
      newdate =
        newdate.getFullYear() +
        "-" +
        addZero(newdate.getMonth() + 1) +
        "-" +
        addZero(newdate.getDate()) +
        " " +
        strTime;
    }
    return newdate;
  };

  static momentLocalConversion = (date, showTime = true) => {
    if (date) {
      const dateFormat = showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
      return moment
        .utc(date, [
          "YYYY-MM-DD HH:m", "MM-DD-YYYY HH:mm", "YYYY-MM-DD"
        ])
        .local()
        .format(dateFormat);
    }
    return null;
  };
  static momentLocalConversionForVersionDate = (date, showTime = true) => {
    if (date) {
      const dateFormat = showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
      return moment
        .utc(date, [
          "YYYY-MM-DD HH:m", "MM-DD-YYYY HH:mm", "YYYY-MM-DD",
          "YYYYY-MM-DD HH:m", "MM-DD-YYYYY HH:mm", "YYYYY-MM-DD",
          "YYYYYY-MM-DD HH:m", "MM-DD-YYYYYY HH:mm", "YYYYYY-MM-DD",
          "YYYYYYY-MM-DD HH:m", "MM-DD-YYYYYYY HH:mm", "YYYYYYY-MM-DD"
        ])
        .local()
        .format(dateFormat);
    }
    return null;
  };
  static momentLocalConversionForLock = (date, showTime = true) => {
    if (date) {
      const dateFormat = showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
      return moment
        .utc(date, ["YYYY-MM-DD HH:m:ss", "MM-DD-YYYY HH:mm:ss", "YYYY-MM-DD"])
        .local()
        .format(dateFormat);
    }
    return null;
  };
  static getUtcCurrentTime = () => {
    return moment.utc().format("YYYY-MM-DD");
  };

  static getCurrentTime = () => {
    return moment().format("YYYY-MM-DD");
  };
  static formatDate = (date = null) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  static getUtcCurrentTimeWithHour = () => {
    return moment.utc().format("YYYY-MM-DD HH:mm");
  };

  static getUtcCurrentTimeforExport = () => {
    return moment.utc().format("YYYYMMDDHHmmss");
  };

  static getFileKeyName = (type) => {
    for (const key in APP_CONST.FILE_TYPES) {
      if (APP_CONST.FILE_TYPES[key].indexOf(type.toLowerCase()) !== -1) {
        return key;
      }
    }
    return type;
  };
  static getIconClass = (type) => {
    const typeName = !APP_CONST.BLACK_LISTED_FILE_FORMATS.includes(type)
      ? type
      : "default";
    let keyIcon = UtilsHelper.getFileKeyName(typeName);
    if (keyIcon) {
      return APP_CONST.AVAILABLE_ICONS.indexOf(keyIcon.toLowerCase()) !== -1
        ? keyIcon
        : "default";
    } else {
      return "default";
    }
  };

  static getDocType = (type, size) => {
    let iconCls = UtilsHelper.getIconClass(type);
    return `${iconCls}${size === null ? "" : "-l"}`;
  };

  static logout = (from = false) => {
    // if (!from) Ale rtHelper.showError(i18n.t("TMF.TOAST_UNAUTHORIZED"));
    window.inactivityClient.logOut();
  };
  static getZoneId = (origid) => {
    return origid ? origid.split(".")[0] || "" : "";
  };

  static getSectionId = (origid) => {
    const activeOrigid = origid ? origid.split(".") : "";
    return activeOrigid ? `${activeOrigid[0]}.${activeOrigid[1]}` : "";
  };

  static showFileHistory = (field) => {
    return APP_CONST.HISTORY_FILE_FILEDS.indexOf(field) !== -1;
  };

  static prepareDownloadReq = (fileprops) => {
    const passDownloadFileData = {};
    passDownloadFileData.requestPaylod = {};
    if (fileprops.tmfdropzoneid && fileprops.tmfdropzoneid !== "") {
      passDownloadFileData.requestPaylod.documentid = fileprops.tmfdropzoneid;
    } else {
      passDownloadFileData.requestPaylod.documentid =
        fileprops.documentid || fileprops.documentguid || fileprops.fileguid;
    }
    passDownloadFileData.requestPaylod.documentversionguid = fileprops.documentversionguid;
    passDownloadFileData.requestPaylod.fileguid = fileprops.documentguid || fileprops.fileguid;
    passDownloadFileData.requestPaylod.lastmodifiedon =
      fileprops.lastmodifiedon;
    passDownloadFileData.downloadInfo = {};
    passDownloadFileData.downloadInfo.type = fileprops.type || fileprops.fileextension;
    fileprops.downloadtemplate = fileprops.downloadFileName;
    passDownloadFileData.downloadInfo.downloadFileName =
      (fileprops.from && fileprops.from === 'history') ? fileprops.downloadFileName : fileprops.downloadtemplate;
    let docAuditDet = {};
    docAuditDet.documentDetails = fileprops;

    passDownloadFileData.passAuditData = {};
    passDownloadFileData.passAuditData.comments = "Not Downloaded";
    passDownloadFileData.passAuditData.keyId = APP_CONST.HARD_CODED.dummyId;
    passDownloadFileData.passAuditData.keyValues = JSON.stringify(docAuditDet);
    passDownloadFileData.passAuditData.action = "Download";
    passDownloadFileData.passAuditData.status = "Error";
    passDownloadFileData.passAuditData.httpAction = "Post";

    return passDownloadFileData;
  };

  static findUserName = (list, id) => {
    const findObj = _.find(list, { guid: id });
    return !_.isEmpty(findObj) ? `${findObj.displayName}` : "";
  };

  static bytesToMegaBytes = (bytes, digits) => {
    let mbValue = (bytes / (1024 * 1024)).toFixed(digits);
    return mbValue ? `${mbValue} MB` : "0 MB";
  };

  static constructDocumentUploadObject = (uploadFile, guid, props) => {
    const returnObj = {
      tmflocation: "",
      scope: "",
      etmfurl: "",
      documenttitle: "",
      isStar: false,
      artifact: "",
      documenttmflevel: "",
      documenttype: "",
      zone: "",
      section: "",
      location: "SE - Uppsala University Hospital",
    };
    returnObj.artifactid = parseInt(props.origid.split(".")[2]);
    returnObj.documentname = uploadFile.name;
    returnObj.type = UtilsHelper.getFileExtension(uploadFile.name);
    returnObj.tmffilename = uploadFile.name;
    returnObj.origfilename = uploadFile.name;
    returnObj.fileSize = uploadFile.size;
    returnObj.filedate = UtilsHelper.getUtcCurrentTimeWithHour();
    returnObj.origiartifactid = props.origid;
    returnObj.artifact = props.name;
    returnObj.status = "Unpublished";
    // returnObj.documentguid = `${guid}.${returnObj.type}`;
    returnObj.documentguid = guid;
    returnObj.documentid = guid;
    returnObj.zoneid = UtilsHelper.getZoneId(props.origid);
    returnObj.sectionid = UtilsHelper.getSectionId(props.origid);
    returnObj.documentTmfLevelId = APP_CONST.HARD_CODED.dummyId;
    returnObj.isCustomTitle = false;
    returnObj.title = "fileName";
    returnObj.customTitle = null;
    returnObj.datingconventionlabel = props.datingconvention;
    returnObj.version = "";
    returnObj.countriesid = [];
    returnObj.sitesid = [];
    returnObj.documenttype = null;
    returnObj.documenttypevalue = null;
    return returnObj;
  };

  static removeDuplicates = (list, key) => {
    if (!_.isEmpty(list))
      return list.filter(
        (ele, index) =>
          index ===
          list.findIndex(
            (obj) =>
              (!_.isEmpty(obj[key]) && obj[key].toLowerCase()) ===
              (!_.isEmpty(ele[key]) && ele[key].toLowerCase())
          )
      );
    else return list;
  };

  static permissionObject = (data) => {
    const {
      artifactuseraccess,
      rolePermission,
      documenttmflevel,
      sitesid,
      countriesid,
      countrySiteList,
    } = data;
    const allowedSite = countrySiteList.site.map((obj) => obj.id);
    const allowedCountry = countrySiteList.country.map((obj) => obj.id);
    let isIncludes = (arr, target) => target.every((v) => arr.includes(v));

    let writePermission = false;
    let reviewPermission = false;
    const returnReviewPermission = (filterUserAccessArr, from) => {
      let permissionArray = [];
      let reviewCount = 0;
      let permission = false;
      if (from !== "") {
        _.map(filterUserAccessArr, (dataObj) => {
          permissionArray = [...permissionArray, ...dataObj.access];
          if (dataObj.access.includes("REVIEW")) reviewCount++;
          permission = filterUserAccessArr.length === reviewCount;
        });
      } else {
        _.map(filterUserAccessArr, (dataObj) => {
          permissionArray = [...permissionArray, ...dataObj.access];
          if (dataObj.access.includes("REVIEW")) reviewCount++;
          permission = reviewCount !== 0;
        });
      }
      return permission;
    };
    const returnPermissionObj = (from, idList, allowedList) => {
      let findObj = {};
      let filterUserAccessArr = _.filter(artifactuseraccess, (filterObj) => {
        return filterObj.userScope === from;
      });
      const scopeCodeList = !_.isEmpty(filterUserAccessArr)
        ? filterUserAccessArr.map((obj) => obj.scopeCode)
        : [];
      if (from !== "Study") {
        if (_.isEmpty(idList)) {
          writePermission =
            rolePermission === "WRITE" || rolePermission === "SIGN";
          reviewPermission = returnReviewPermission(filterUserAccessArr, from);
        } else {
          let permissionArray = [];
          let reviewCount = 0;
          _.map(filterUserAccessArr, (dataObj) => {
            if (idList.includes(dataObj.scopeCode)) {
              permissionArray = [...permissionArray, ...dataObj.access];
              if (dataObj.access.includes("REVIEW")) reviewCount++;
            }
          });
          if (
            isIncludes(allowedList, idList) &&
            isIncludes(scopeCodeList, idList)
          ) {
            writePermission =
              permissionArray.includes("WRITE") ||
              permissionArray.includes("SIGN");
            reviewPermission = idList.length === reviewCount;
          } else {
            writePermission = false;
            reviewPermission = false;
          }
        }
      } else {
        findObj = _.find(filterUserAccessArr, (filterObj) => {
          return filterObj.userScope === "Study";
        });
        writePermission =
          findObj &&
          (findObj.access.includes("WRITE") || findObj.access.includes("SIGN"));
        reviewPermission = findObj && findObj.access.includes("REVIEW");
      }
    };
    switch (documenttmflevel) {
      case "Site":
        returnPermissionObj(documenttmflevel, sitesid, allowedSite);
        break;
      case "Country":
        returnPermissionObj(documenttmflevel, countriesid, allowedCountry);
        break;
      case "Trial":
        returnPermissionObj("Study");
        break;
      default:
        writePermission =
          _.isEmpty(documenttmflevel) &&
          (rolePermission === "WRITE" || rolePermission === "SIGN");
        reviewPermission = returnReviewPermission(artifactuseraccess, "");
        break;
    }
    return { writePermission, reviewPermission };
  };

  static artifactErrorOperations = (list, data, action) => {
    let errorList = { ...list };
    let returnErrorList = errorList[data.origiartifactid] || [];
    const findIndex = _.findIndex(returnErrorList, {
      documentguid: data.documentguid || data.documentid,
    });
    switch (action) {
      case "add":
        returnErrorList.unshift(data);
        break;
      case "delete":
        if (findIndex !== -1) returnErrorList.splice(findIndex, 1);
        break;
      default:
        break;
    }
    errorList[data.origiartifactid] = returnErrorList;
    return errorList;
  };

  static artifactOperations = (list, data, action) => {
    const updatedList = list.map((obj) => {
      let artifactObj = { ...obj };
      if (artifactObj.origid === data.origiartifactid) {
        let returnDocumentList = obj.docItemArr || [];
        const findIndex = _.findIndex(obj.docItemArr, {
          documentguid: data.documentguid || data.documentid,
        });
        const findObj = _.find(obj.docItemArr, {
          documentguid: data.documentguid || data.documentid,
        });
        switch (action) {
          case "add":
            returnDocumentList.unshift(data);
            break;
          case "update":
            if (findIndex !== -1)
              returnDocumentList[findIndex] = { ...findObj, ...data };
            else returnDocumentList.unshift(data);
            break;
          case "delete":
            if (findIndex !== -1) returnDocumentList.splice(findIndex, 1);
            break;
          case "saveAs":
            const findDocIndex = _.findIndex(obj.docItemArr, {
              documentid: data.documentid,
            });
            const findDoc = _.find(obj.docItemArr, {
              documentid: data.documentid,
            });
            if (findDocIndex !== -1)
              returnDocumentList[findDocIndex] = { ...findDoc, ...data };
            else returnDocumentList.unshift(data);
            break;
          default:
            break;
        }
        const documentList = UtilsHelper.removeDuplicates(
          returnDocumentList,
          "documentguid"
        );
        artifactObj.docItemArr = [...documentList];
        return artifactObj;
      } else {
        let returnDocumentList = obj.docItemArr || [];
        returnDocumentList = _.filter(returnDocumentList, (item) => {
          return (
            item.documentguid !== data.documentguid &&
            item.origiartifactid !== data.origiartifactid
          );
        });
        artifactObj.docItemArr = UtilsHelper.removeDuplicates(
          returnDocumentList,
          "documentguid"
        );
        return artifactObj;
      }
    });
    return updatedList;
  };

  static constructMetricsInfo = (metrics) => {
    const artifactMetrics = [...metrics.artifactinfo];
    const zoneMetrics = [];
    const sectionMetrics = [];
    // finding section metrics
    artifactMetrics.forEach((metric) => {
      const lastIndex = metric.artifactid.lastIndexOf(".");
      const id = metric.artifactid.substring(0, lastIndex);
      const obj = remove(sectionMetrics, { sectionid: id })[0];
      let newObj;
      if (!_.isEmpty(obj)) {
        // similar section data
        newObj = {
          sectionid: id,
          awaitingreview: obj.awaitingreview + metric.awaitingreview,
          flaggedbyqc: obj.flaggedbyqc + metric.flaggedbyqc,
          finalized: obj.finalized + metric.finalized,
          unpublished: obj.unpublished + metric.unpublished,
          note: obj.note + metric.note,
          docrequiredlevels: !_.isEmpty(obj.docrequiredlevels)
            ? obj.docrequiredlevels
            : metric.docrequiredlevels,
          artifactinfo: [...obj.artifactinfo, { ...metric }],
        };
      } else {
        // different section data
        newObj = {
          sectionid: id,
          awaitingreview: metric.awaitingreview,
          flaggedbyqc: metric.flaggedbyqc,
          finalized: metric.finalized,
          unpublished: metric.unpublished,
          note: metric.note,
          docrequiredlevels: metric.docrequiredlevels,
          artifactinfo: [metric],
        };
      }
      sectionMetrics.push(newObj);
    });
    // finding zone metrics
    sectionMetrics.forEach((metric) => {
      const index = metric.sectionid.indexOf(".");
      const id = metric.sectionid.substring(0, index);
      const obj = remove(zoneMetrics, { zoneid: id })[0];
      let newObj;
      if (!_.isEmpty(obj)) {
        // similar zone data
        newObj = {
          zoneid: id,
          awaitingreview: obj.awaitingreview + metric.awaitingreview,
          flaggedbyqc: obj.flaggedbyqc + metric.flaggedbyqc,
          finalized: obj.finalized + metric.finalized,
          unpublished: obj.unpublished + metric.unpublished,
          note: obj.note + metric.note,
          docrequiredlevels: !_.isEmpty(obj.docrequiredlevels)
            ? obj.docrequiredlevels
            : metric.docrequiredlevels,
          sectioninfo: [...obj.sectioninfo, { ...metric }],
        };
      } else {
        // different zone data
        newObj = {
          zoneid: id,
          awaitingreview: metric.awaitingreview,
          flaggedbyqc: metric.flaggedbyqc,
          finalized: metric.finalized,
          unpublished: metric.unpublished,
          note: metric.note,
          docrequiredlevels: metric.docrequiredlevels,
          sectioninfo: [metric],
        };
      }
      zoneMetrics.push(newObj);
    });
    // getting total count of docs
    let totalMetricsCount = {
      totalawaitingreview: 0,
      totalflaggedbyqc: 0,
      totalfinalized: 0,
      totalunPublished: 0,
      totalNotes: 0
    };
    zoneMetrics.forEach((info) => {
      totalMetricsCount = {
        totalawaitingreview:
          totalMetricsCount.totalawaitingreview + info.awaitingreview,
        totalflaggedbyqc: totalMetricsCount.totalflaggedbyqc + info.flaggedbyqc,
        totalfinalized: totalMetricsCount.totalfinalized + info.finalized,
        totalunPublished: totalMetricsCount.totalunPublished + info.unpublished,
        totalNotes: totalMetricsCount.totalNotes + info.note,
      };
    });
    let containDocPercent = 0;
    if (metrics.artifact !== 0) {
      containDocPercent = Math.round(
        ((metrics.artifactwithdocuments) / metrics.artifact) * 100
      );
    }
    const result = {
      zone: metrics.zone,
      section: metrics.section,
      artifact: metrics.artifact,
      artifactwithdocuments: metrics.artifactwithdocuments,
      artifactmissingdocuments: metrics.artifactmissingdocuments,
      zoneinfo: zoneMetrics,
      awaitingreview: totalMetricsCount.totalawaitingreview,
      finalized: totalMetricsCount.totalfinalized,
      unpublished: totalMetricsCount.totalunPublished,
      note: totalMetricsCount.totalNotes,
      flaggedbyqc: totalMetricsCount.totalflaggedbyqc,
      totaldoccount:
        totalMetricsCount.totalawaitingreview +
        totalMetricsCount.totalflaggedbyqc +
        totalMetricsCount.totalfinalized,
      doccontainspercent: containDocPercent,
      artifactinfo: metrics.artifactinfo,
    };
    return result;
  };
  static getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    const returnObject = {};
    diff.map((key) => {
      returnObject[key] = obj1[key];
    });

    return returnObject;
  };

  static restrictTagsAndSpaces = (value) => {
    // To restrict HTML tags and script tags
    // let updatedValue = value.replace(/(<([^>]+)>)/gi, "");
    let updatedValue = value;
    // To restrict spaces at the beginning of first word
    if (updatedValue.trim() == "") {
      if (/^\s.*/.test(updatedValue)) updatedValue = updatedValue.trim();
    } else {
      if (/^\s.*/.test(updatedValue)) updatedValue = updatedValue;
    }
    return updatedValue;
  };

  static checkCurlyBracesValue = (value, from, documentTemplateNames) => {
    let updatedValue = value;
    let format = /{(.*?)}/;
    let displayNameFormat = /^[*|":/\\<>$]+$/;
    let documentNames = documentTemplateNames;

    if (from !== "displaytemplate" && !_.isEmpty(updatedValue) && !displayNameFormat.test(updatedValue) && !format.test(updatedValue)) {
      return true;
    } else {
      if (!_.isEmpty(updatedValue) && format.test(updatedValue)) {
        if (updatedValue && updatedValue.includes("{}") === false) {
          let path = updatedValue;
          const paramsPattern = /[^{\}]+(?=})/g;
          let extractParams = path.match(paramsPattern);
          const docNames = Array.from(new Set(extractParams));
          let isValid;
          if (extractParams && extractParams !== null) {
            isValid = documentNames.filter(Names => extractParams.includes(Names));
          }
          if (isValid && isValid !== undefined && extractParams !== null) {
            if (isValid.length === docNames.length)
              return true;
            else
              return false;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  static getInvalidNames = (templateName, documentTemplateNames) => {
    const paramsPattern = /[^{\}]+(?=})/g;
    let format = /{(.*?)}/;
    let extractParams;
    if (!_.isEmpty(templateName) && format.test(templateName)) {
      extractParams = templateName.match(paramsPattern);
    }
    let invalidError = "";
    if (extractParams && extractParams !== null) {
      let invalidNames = extractParams.filter(num => !documentTemplateNames.some(id => id === num));
      if (templateName && !templateName.includes("{}")) {
        invalidError = invalidNames.map(item => "{" + item + "}");
      } else {
        invalidError = "{}" + invalidNames.map(item => "{" + item + "}");
      }
    } else if (templateName && templateName.includes("{}")) {
      invalidError = "{}";
    }
    return invalidError;
  }

  static constructErrorMsg = (errorObj) => {
    const { responseException } = errorObj;
    const { exceptionMessage } = responseException;
    const { errors } = exceptionMessage;
    let errorResponse = exceptionMessage;
    if (!_.isEmpty(errors)) {
      errorResponse = errors[Object.keys(errors)[0]];
      errorResponse = errorResponse[0];
    }
    return errorResponse;
  };

  static errorDownloadFile = (error, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    const errorBlob = new Blob([error]);
    link.href = URL.createObjectURL(errorBlob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

  static fileExtensionRemoval = (value) => {
    return value.replace(/\.[^/.]+$/, "");
  };
  static fileInvalidCharsRemoval = (value) => {
    let val = value.replace(/[\/\\:*?"<>|]/g, "_");
    if (val.length > 200) val = val.substring(0, 200);
    return val;
  };

  static constructDisplayOrder = (status, time) => {
    switch (status) {
      case "Unpublished":
        return 3;
      case "Awaiting review":
        return 2;
      case "Flagged by QC":
        return 2;
      case "Finalized":
        return 2;
      case "Scanning in progress":
        return 1;
      default:
        return 0;
        break;
    }
  };

  static randomNumber = (numbers) => {
    return numbers[Math.floor(Math.random() * numbers.length)];
  };

  static isUUID = (uuid) => {
    let s = "" + uuid;
    s = s.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
    if (s === null) {
      return false;
    }
    return true;
  }
  static getFormattedDateTime = (format, fullDate, locale) => {
    if (fullDate === null) {
      return;
    }
    let filterDate = fullDate.split(".")[0];
    if (fullDate.includes("Z")) filterDate = fullDate.substring(0, fullDate.length - 1);
    let newdate = new Date(filterDate);
    newdate = new Date(`${newdate} UTC`);
    let replacedFormat = format;
    replacedFormat = replacedFormat.replace(/\bdddd\b/, "cccc")
      .replace(/\bH:mm:ss tt\b/, "H:mm:ss a").replace(/\bHH:mm:ss tt\b/, "HH:mm:ss a")
      .replace(/\bH:mm tt\b/, "H:mm a").replace(/\bHH:mm tt\b/, "HH:mm a")
      .replace(/\bh:mm:ss tt\b/, "h:mm:ss a").replace(/\bhh:mm:ss tt\b/, "hh:mm:ss a")
      .replace(/\bh:mm tt\b/, "h:mm a").replace(/\bhh:mm tt\b/, "hh:mm a");
    return DateTime.local(newdate.getFullYear(), newdate.getMonth() + 1, newdate.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds()).setLocale(locale)
      .toFormat(replacedFormat);
  };
  static getFormattedDateTimeNoLocalConversion = (format, fullDate, locale) => {
    if (fullDate === null) {
      return;
    }
    let splitedDate = fullDate.split("-");
    let replacedFormat = format;
    replacedFormat = replacedFormat.replace(/\bdddd\b/, "cccc")
      .replace(/\bH:mm:ss tt\b/, "H:mm:ss a").replace(/\bHH:mm:ss tt\b/, "HH:mm:ss a")
      .replace(/\bH:mm tt\b/, "H:mm a").replace(/\bHH:mm tt\b/, "HH:mm a")
      .replace(/\bh:mm:ss tt\b/, "h:mm:ss a").replace(/\bhh:mm:ss tt\b/, "hh:mm:ss a")
      .replace(/\bh:mm tt\b/, "h:mm a").replace(/\bhh:mm tt\b/, "hh:mm a");
    return DateTime.local(parseInt(splitedDate[0]), parseInt(splitedDate[1]), parseInt(splitedDate[2])).setLocale(locale)
      .toFormat(replacedFormat);
  };
}
