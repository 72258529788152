import * as actionTypes from "app/store/actions/actionTypes";

export const fetchZoneData = () => ({ type: actionTypes.FETCH_ZONE_DATA });
export const zoneDataFetchSuccess = (payload) => ({
  type: `${actionTypes.FETCH_ZONE_DATA}_SUCCESS`,
  payload,
});

export const setArtifactItem = (payload) => ({
  type: actionTypes.SET_ARTIFACT_ITEMS,
  payload,
});

export const resetSelection = () => ({
  type: actionTypes.RESET_SELECTION,
});

export const updateSelection = (payload) => ({
  type: actionTypes.UPDATE_SELECTION,
  payload,
});

export const fetchAllClientApis = () => ({
  type: actionTypes.FETCH_ALL_CLIENT_APIS,
});

export const getCountrySiteListSuccess = (payload) => ({
  type: `${actionTypes.GET_COUNTRY_SITE_LIST}_SUCCESS`,
  payload,
});

export const setFilterCoutryID = (id, name) => ({
  type: actionTypes.SELECTED_FILTER_ID,
  id,
  name,
});

export const getDocumentsByArtifact = (id) => ({
  type: actionTypes.GET_DOCUMENY_BY_ARTIFACT,
  payload: id,
});
export const getDocumentsBySection = (id, from, isNewDocVersionCreated) => ({
  type: actionTypes.GET_DOCUMENY_BY_SECTION,
  payload: id,
  from,
  isNewDocVersionCreated
});
export const getDocumentsBySectionSuccess = (payload, from) => ({
  type: `${actionTypes.GET_DOCUMENY_BY_SECTION}_SUCCESS`,
  payload,
  from,
});
export const getDocumentsBySectionFailed = (from) => ({
  type: `${actionTypes.GET_DOCUMENY_BY_SECTION}_FAILED`,
  from,
});

export const fetchAllClientApisFailed = () => ({
  type: `${actionTypes.FETCH_ALL_CLIENT_APIS}_FAILED`,
});

export const getAppInitConfigSuccess = (payload) => ({
  type: `${actionTypes.GET_APP_INIT_CONFIG}_SUCCESS`,
  payload,
});

export const getRoleListSuccess = (payload) => ({
  type: `${actionTypes.GET_ROLE_LIST}_SUCCESS`,
  payload,
});

export const getStudyInfoSuccess = (payload) => ({
  type: `${actionTypes.GET_STUDY_INFO}_SUCCESS`,
  payload,
});

export const getUserInfoSuccess = (payload) => ({
  type: `${actionTypes.GET_USER_INFO}_SUCCESS`,
  payload,
});

export const getUserListSuccess = (payload) => ({
  type: `${actionTypes.GET_USER_LIST}_SUCCESS`,
  payload,
});

export const getOrgId = (payload) => ({
  type: `${actionTypes.GET_ORG_ID}_SUCCESS`,
  payload,
});

export const fetchSearchResult = (payload, isLocalStore = false) => ({
  type: actionTypes.FETCH_SEARCH_RESULT,
  payload,
  isLocalStore
});
export const makeSearchView = (payload) => ({
  type: `${actionTypes.MAKE_SEARCH_VIEW}`,
  payload,
});
export const fetchSearchResultSuccess = (payload, isSearchView = false) => ({
  type: `${actionTypes.FETCH_SEARCH_RESULT}_SUCCESS`,
  payload,
  isSearchView
});
export const fetchSearchResultFailure = (payload) => ({
  type: `${actionTypes.FETCH_SEARCH_RESULT}_FAILED`,
  payload,
});

export const fetchAllAggridData = (payload, initLoad, refreshPage = false, isNewDocVersionCreated) => ({
  type: actionTypes.FETCH_ALL_AGGRID_DATA,
  payload,
  initLoad,
  refreshPage,
  isNewDocVersionCreated
});
export const fetchAllAggridDataSuccess = (payload, searchView) => ({
  type: `${actionTypes.FETCH_ALL_AGGRID_DATA}_SUCCESS`,
  payload,
  searchView
});
export const fetchAllAggridDataFailure = (payload) => ({
  type: `${actionTypes.FETCH_ALL_AGGRID_DATA}_FAILED`,
  payload,
});


export const goToBrowserView = (payload) => ({
  type: actionTypes.GO_TO_BROWSER_VIEW,
  payload,
});

export const setArtifactPaginationInfo = (payload) => ({
  type: `${actionTypes.SET_ARTIFACT_PAGINATION_INFO}`,
  payload,
});
export const fetchMetricsData = (payload) => ({
  type: actionTypes.FETCH_METRICS_DATA,
  payload,
});

export const fetchMetricsDataSuccess = (payload) => ({
  type: `${actionTypes.FETCH_METRICS_DATA}_SUCCESS`,
  payload,
});

export const fetchMetricsDataFailed = () => ({
  type: `${actionTypes.FETCH_METRICS_DATA}_FAILED`,
});

export const setViewPerPage = (payload) => ({
  type: `${actionTypes.SET_SEARCH_VIEW_PER_PAGE}`,
  payload,
});

export const setSearchPageInfo = (payload) => ({
  type: `${actionTypes.SET_SEARCH_PAGE_INFO}`,
  payload,
});

export const resetDocumentDataList = (payload) => ({
  type: `${actionTypes.RESET_DOCUMENT_DATA_LIST}`,
  payload,
});

export const removeSearchDeleteDoc = (payload) => ({
  type: actionTypes.REMOVE_SEARCH_DELETE_DOC,
  payload
});
export const getAllDataClicked = (payload) => ({
  type: actionTypes.GET_ALL_DATA_CLICKED,
  payload
});
export const aggridResultCount = (payload) => ({
  type: actionTypes.GET_AGGRID_RESULT_COUNT,
  payload
});
export const enableAggridIcon = (payload) => ({
  type: actionTypes.ENABLE_AGGRID_ICON,
  payload
});
export const saveAllAgData = (payload) => ({
  type: actionTypes.SAVE_ALL_AG_DATA,
  payload
});
export const removeInvalidDocument = (payload) => ({
  type: actionTypes.REMOVE_INVALID_DOCUMENT,
  payload
});
export const isUploadedNewVersionFile = (payload) => ({
  type: actionTypes.UPLOADED_NEW_VERSION_FILE,
  payload
});



