import { put, takeEvery, delay, take, takeLatest, select } from "redux-saga/effects";
import * as actionTypes from "app/store/actions/actionTypes";
import * as actions from "app/store/actions/templateActions";
import * as documentActions from "app/store/actions/documentAction";
import * as dialogActions from "app/store/actions/dialogAction";
import * as actionsLoader from "app/store/actions/loaderActions";
import { TemplateService } from "app/secure/services/templateService";
import { DocumentService } from "app/secure/services/documentService";
import AlertHelper from "app/core/alertHelper";
import i18n from "i18nnext";
import AzureFileuploadHelper from "app/core/azureFileuploadHelper";
import GetIPAndBrowserInfo from "app/core/getIPAndBrowserInfo";
import { StorageHelper } from "app/core/storageHelper";
import { UtilsHelper } from "app/core/utilsHelper";
import _ from "underscore";
import { addConsoleHandler } from "selenium-webdriver/lib/logging";
import * as zoneActions from "app/store/actions/zoneActions";

export function* fetchTemplate() {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield TemplateService.doFetchTemplate();
    yield put(actions.fetchTemplateSuccess(response.data));
    yield put(actionsLoader.hideLoader());
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actions.fetchTemplateFailed());
  }
}

export function* fetchConfiguration() {
  try {
    const response = yield TemplateService.doFetchConfiguration();
    yield put(actions.fetchConfigurationSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchConfigurationFailed());
  }
}

export function* getTMFStructureDataSaga() {
  try {
    const response = yield DocumentService.tmfStructureDataService();
    yield put(actions.getTMFStructureDataSuccess(response.data));
    yield put(actions.getMilestoneCount());
  } catch (error) {
    yield put(actions.getTMFStructureDataFailed());
  }
}

export function* getAllTemplatesSaga(data) {
  try {
    const orgAndViedocResponse =
      yield TemplateService.doFetchOrgAndViedocTemplates(data.payload);
    const studyResponse = yield TemplateService.doFetchStudyTemplates();
    let getAllTemplates = [];
    let orgAndViedoc = orgAndViedocResponse.data.result || [];
    orgAndViedoc.map((res) => {
      getAllTemplates.push(res);
    });
    let study = studyResponse.data.result || [];
    study.map((res) => {
      getAllTemplates.push(res);
    });
    yield put(actions.getAllTemplatesSuccess(getAllTemplates));
  } catch (error) {
    yield put(actions.getAllTemplatesFailed());
  }
}
export function* getStudyInfoSaga(data) {
  try {
    const response = yield TemplateService.doFetchStudyInfo();
    const { result, isError } = response.data;
    if (!isError) {
      // To find OS, browser name & ip address of user
      let payload = {
        userId: result.userid,
        studyId: result.studyid,
      };
      StorageHelper.setIPAndBrowserDetails({ ...payload });

      // To find studyid & userid of a user
      StorageHelper.setStudyConfig({ ...result });
      StorageHelper.clearRecentSearchInfo();
      yield put(zoneActions.goToBrowserView());
      yield put(actions.getStudyInformationSuccess(result));
    }
  } catch (error) {
    yield put(actions.getStudyInformationFailed());
  }
}

export function* getLoggedInUser() {
  try {
    const response = yield TemplateService.doFetchLoggedInUser();
    yield put(actions.getStudyInformationSuccess(response.data.result));
  } catch (error) {
    yield put(actions.getStudyInformationFailed());
  }
}

export function* selectTemplateSaga(data) {
  try {
    let response = "";
    if (data.templatecategory !== "Study template")
      response = yield TemplateService.doSelectTemplate(data.payload);
    else response = yield TemplateService.doSelectStudyTemplate(data.payload);
    if (response.data.isError) {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.selectTemplateFailed());
    } else {
      yield put(actions.selectTemplateSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.selectTemplateFailed());
  }
}

export function* instantiateTemplateSaga(data) {
  try {
    const response = yield TemplateService.doInstantiateTemplate(data.payload);
    if (!response.data.isError) {
      yield put(actions.instantiateTemplateSuccess(response.data));
      yield put(actions.getTMFStructureData());
    } else {
      yield put(
        actionsLoader.showError(
          response.data.responseException.exceptionMessage.title
        )
      );
      yield put(actions.instantiateTemplateFailed());
    }
  } catch (error) {
    yield put(actions.instantiateTemplateFailed());
  }
}

export function* getViewZoneSectionArtifactSaga(action) {
  try {
    const response = yield TemplateService.doFetchZoneSectionArtifact(
      action.payload
    );
    const result = {
      from: action.payload.from,
      mode: action.payload.mode,
      ...response.data.result,
    };
    yield put(actions.getViewZoneSectionArtifactSuccess(result));
  } catch (error) {
    yield put(actions.getViewZoneSectionArtifactFailed());
  }
}

export function* exportTemplateSaga(data) {
  const { payload } = data;
  try {
    let response = "";
    let reqPayload = { templateId: payload.templateid };

    switch (payload.templatecategory) {
      case "Study template":
        response = yield TemplateService.doExportStudyTemplate(reqPayload);
        break;
      case "TMF Export":
        response = yield TemplateService.doExportTmfTemplate(reqPayload);
        break;
      default: {
        // default case for Organization template and Viedoc System Template
        response = yield TemplateService.doExportOrgAndViedocTemplates(
          reqPayload
        );
        break;
      }
    }

    if (response.status === 500) {
      if (payload.templatecategory === "Study template") {
        yield put(
          actionsLoader.showError(i18n.t("TMF.TOAST_EXPORTTEMPLATEFAILED"))
        );
      } else if (payload.templatecategory === "TMF Export") {
        yield put(
          actionsLoader.showError(i18n.t("TMF.TOAST_EXPORTSTRUCTUREFAILED"))
        );
      } else {
        yield put(
          actionsLoader.showError(i18n.t("TMF.TOAST_EXPORTTEMPLATEFAILED"))
        );
      }
    }

    if (response.status === 200) {
      const blob = new Blob([response.data]);
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      const filename = `${payload.templateName}.xlsx`;
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      yield put(actions.exportTemplateSuccess(payload.templateid));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actions.exportTemplateFailed(payload.templateid));
  }
}

export function* deleteTemplate(data) {
  try {
    let response;
    const { payload } = data;

    if (payload.templateCategory !== "Study template") {
      response = yield TemplateService.doDeleteOrgAndViedocTemplate(
        payload.templateId
      );
    } else {
      response = yield TemplateService.doDeleteStudyTemplate(
        payload.templateId
      );
    }

    if (response.status !== 200) {
      yield put(
        actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD"))
      );
    }
    if (response.status === 200) {
      const delTemplateData = response.data.result;
      yield put(actions.deleteTemplateSuccess(delTemplateData));
    }
  } catch (error) {
    yield put(actions.deleteTemplateFailed());
  }
}

export function* getEtmfArchivingSaga(data) {
  try {
    const etmfResponse = yield TemplateService.doGetEtmfArchiving(data.payload);
    yield put(actions.getEtmfArchivingDataSuccess(etmfResponse));
  } catch (error) {
    yield put(actions.getEtmfArchivingDataFailed());
  }
}

export function* exportEtmfDocumentsSaga(data) {
  try {
    const response = yield TemplateService.doStartExportDocuments(data.payload);
    if (response.status !== 200) {
      yield put(
        actions.exportEtmfDocumentsSuccess({
          status: "ERROR",
          exportFrom: "failed",
        })
      );
    }
  } catch (error) {
    yield put(actions.exportEtmfDocumentsFailed());
  }
}

export function* cancelEtmfDocumentsSaga(data) {
  try {
    const response = yield TemplateService.doCancelExportDocuments(
      data.payload
    );
    if (response.status === 200) {
      yield put(
        actions.exportEtmfDocumentsSuccess({
          ...response.data,
          exportFrom: "cancel",
        })
      );
    } else {
      yield put(actions.exportEtmfDocumentsFailed());
    }
  } catch (error) {
    yield put(actions.exportEtmfDocumentsFailed());
  }
}

export function* watchEtmfDocumentsSaga(data) {
  try {
    const response = yield TemplateService.doWatchExportDocuments(data.payload);
    if (response.status === 200) {
      const { tmfStudyarchivedto } = response.data;
      if (tmfStudyarchivedto.status === "COMPLETED") {
        yield put(
          actions.exportEtmfDocumentsSuccess({
            ...response.data,
            exportFrom: "finished",
          })
        );
        const etmfResponse = yield TemplateService.doGetEtmfArchiving(
          data.payload.ArchiveId
        );
        yield put(actions.getEtmfArchivingDataSuccess(etmfResponse));
      } else if (tmfStudyarchivedto.status === "ERROR") {
        yield put(
          actions.exportEtmfDocumentsSuccess({
            ...response.data,
            exportFrom: "failed",
          })
        );
      } else if (tmfStudyarchivedto.status === "CANCELLED") {
        yield put(
          actions.exportEtmfDocumentsSuccess({
            ...response.data,
            exportFrom: "cancel",
          })
        );
      } else {
        const requestPayload = { ArchiveId: data.payload.ArchiveId };
        yield delay(3000);
        yield put(actions.watchExportEtmfDocuments(requestPayload));
      }
    }
  } catch (error) {
    yield put(actions.exportEtmfDocumentsFailed());
  }
}

export function* downloadEtmfDocumentsSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield TemplateService.dodownloadExportFileUrl(
      data.payload
    );
    if (!response.data.isError) {
      const { result } = response.data;
      yield put(actions.downloadEtmfDocumentsSuccess(result));
      let fileurl = atob(result.sastoken);
      yield AzureFileuploadHelper.downloadAzureFile(fileurl, data.payload);
      yield put(actionsLoader.hideLoader());
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(
        actionsLoader.showError(
          `${i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")} - ` + errorMessage
        )
      );
      yield put(actionsLoader.hideLoader());
      yield put(actions.downloadEtmfDocumentsFailed());
    }
  } catch (error) {
    yield put(actionsLoader.hideLoader());
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_FILEDOWNLOADFAILED")));
  }
}

export function* maintenanceOnOffSaga(data) {
  try {
    const response = yield TemplateService.doMaintenanceOnOff(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      const { issuccess, message } = result;
      if (issuccess) {
        yield put(actions.maintenanceOnOffSuccess(result));
        if (data.status !== data.adminStatus) {
          yield put(actions.getTMFStructureChanges());
        }
        yield put(actions.getTMFStructureData());
      } else {
        yield put(actions.maintenanceOnOffFailed());
        yield put(actionsLoader.showError(message));
      }
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.maintenanceOnOffFailed());
    }
  } catch (error) {
    yield put(actions.maintenanceOnOffFailed());
  }
}

export function* maintenanceStatusSaga() {
  try {
    const response = yield TemplateService.getMaintenanceStatus();
    const { isError } = response.data;
    if (!isError) {
      const { message } = response.data.result;
      yield put(actions.maintenanceStatusSuccess(message));
    } else {
      yield put(actions.maintenanceStatusFailed());
    }
  } catch (error) {
    yield put(actions.maintenanceStatusFailed());
  }
}

export function* addNewZoneSaga(data) {
  try {
    const response = yield TemplateService.doAddNewZone(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      if (!result.issuccess) {
        yield put(actionsLoader.showError(result.message));
      } else {
        yield put(actions.addNewZoneSuccess(result));
        AlertHelper.showSuccess(i18n.t("ADMIN.TOAST_NEWZONEADDSUCCESS"));
      }
    } else {
      yield put(actionsLoader.showError(i18n.t("ADMIN.TOAST_NEWZONEADDFAILED")));
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("ADMIN.TOAST_NEWZONEADDFAILED")));
  }
}

export function* editZoneSaga(data) {
  try {
    const response = yield TemplateService.editZone(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.editZoneSuccess(result));
      yield put(actions.getTMFStructureChanges());
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.editZoneFailed(errorMessage));
    }
  } catch (error) {
    yield put(actions.editZoneFailed(i18n.t("ADMIN.TOAST_EDITZONEFAILED")));
  }
}

export function* editSectionSaga(data) {
  try {
    const response = yield TemplateService.editSection(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.editSectionSuccess(result));
      yield put(actions.getTMFStructureChanges());
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.editSectionFailed(errorMessage));
    }
  } catch (error) {
    yield put(actions.editSectionFailed(i18n.t("ADMIN.TOAST_EDITSECTIONFAILED")));
  }
}

export function* renameTemplateSaga(data) {
  const { payload, fromCategory, isSelect } = data;
  try {
    let response = {};
    switch (fromCategory) {
      case "Organization template":
        response = yield TemplateService.doRenameOrgTemplate(payload);
        break;
      case "Study template":
        response = yield TemplateService.doRenameStudyTemplate(payload);
        break;
      default:
        break;
    }
    const { isError } = response.data;
    if (!isError) {
      if (isSelect) yield TemplateService.doRenameSelectedTemplate(payload);
      yield put(actions.renameTemplateSuccess(response.data.result));
      yield put(actionsLoader.showError(""));
    } else {
      yield put(
        actionsLoader.showError(i18n.t("ADMIN.TOAST_DOCUPDATEFAILEDREFRESHRELOAD"))
      );
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("ADMIN.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
  }
}

export function* addNewArtifactSaga(data) {
  try {
    const response = yield TemplateService.doAddNewArtifact(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      const { issuccess, zone, message } = result;
      if (issuccess && !_.isEmpty(zone)) {
        const reqPayload = {
          zoneList: zone,
          zoneId: data.payload.zone && data.payload.zone.id,
          sectionId: data.payload.section && data.payload.section.id,
          artifactId: data.payload.artifact && data.payload.artifact.id,
          lastmodifiedon: result.lastmodifiedon,
        };
        yield put(actions.addNewArtifactSuccess(reqPayload));
        yield put(actions.getTMFStructureChanges());
      } else {
        yield put(actions.addNewArtifactFailed(message));
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.addNewArtifactFailed(errorMessage));
    }
  } catch (error) {
    yield put(
      actions.addNewArtifactFailed(i18n.t("ADMIN.TOAST_ADDNEWARTIFACTFAILED"))
    );
  }
}

export function* getMileStoneListSaga() {
  try {
    const response = yield TemplateService.doFetchMileStoneList();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getMileStoneListSuccess(result));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
  }
}

export function* getDatingConventionListSaga(data) {
  try {
    const response = yield TemplateService.doFetchDatingConventionList();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDatingConventionListSuccess(result));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
  }
}

export function* deleteStructure(data) {
  try {
    const response = yield TemplateService.deleteStruture(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actionsLoader.showError(""));
      yield put(actions.deleteStructureSuccess({ ...data.payload, ...result }));
      yield put(actions.getTMFStructureChanges());
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.deleteStructureFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actions.deleteStructureFailed(i18n.t("ADMIN.TOAST_DELETEFAILED")));
  }
}

export function* getTMFStructureChangesSaga() {
  try {
    const response = yield TemplateService.doTMFStructureChanges();
    const { isError, result } = response.data;
    if (!isError) {
      const { issuccess, message } = result;
      if (!issuccess) {
        yield put(actionsLoader.showError(message));
        yield put(actions.getTMFStructureChangesSuccess(result));
      } else {
        yield put(actionsLoader.showError(""));
        yield put(actions.getTMFStructureChangesSuccess(result));
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.getTMFStructureChangesFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actionsLoader.showError(""));
  }
}

export function* revertStructureChangeSaga(data) {
  try {
    const response = yield TemplateService.doRevertStructureChange(
      data.payload
    );
    const { isError, result } = response.data;
    if (!isError) {
      const { issuccess, message } = result;
      if (!issuccess) {
        yield put(actionsLoader.showError(message));
        yield put(actions.revertStructureChangeSuccess(result));
      } else {
        yield put(actionsLoader.showError(""));
        yield put(actions.revertStructureChangeSuccess(result));
        yield put(actions.getTMFStructureData());
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.revertStructureChangeFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(
      actions.revertStructureChangeFailed(i18n.t("ADMIN.TOAST_REVERTFAILED"))
    );
  }
}

export function* applyStructureChangeSaga(data) {
  try {
    const response = yield TemplateService.doApplyStructureChange(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      const { issuccess, message } = result;
      if (!issuccess) {
        yield put(actionsLoader.showError(message));
        yield put(actions.applyStructureChangeSuccess(result));
      } else {
        yield put(actionsLoader.showError(""));
        yield put(actions.applyStructureChangeSuccess(result));
        yield put(
          actionsLoader.showError(
            i18n.t("ADMIN.TOAST_CHANGESAPPLIEDSUCCESS"),
            false,
            true
          )
        );
        yield put(actions.getTMFStructureData());
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actionsLoader.showError(errorMessage));
      yield put(actions.applyStructureChangeFailed(errorMessage));
    }
  } catch (error) {
    yield put(actions.applyStructureChangeFailed(i18n.t("ADMIN.TOAST_APPLYFAILED")));
  }
}

export function* getEnablingMilestoneStatusSaga() {
  try {
    const response = yield TemplateService.getEnablingMilestoneStatus();
    const { isError } = response.data;
    if (!isError) {
      yield put(
        actions.getEnablingMilestoneStatusSuccess(
          response.data.result
        )
      );
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.getEnablingMilestoneStatusFailed());
    }
  } catch (error) {
    yield put(actions.getEnablingMilestoneStatusFailed());
  }
}

export function* updatingEnablingMilestoneStatusSaga(data) {
  try {
    const response = yield TemplateService.doEnablingMilestoneStatus(data.payload);
    const { isError } = response.data;
    if (!isError) {
      yield put(
        actions.updateMileStoneStatusSuccess(
          response.data.result
        )
      );
      yield put(actions.getEnablingMilestoneStatus());
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.updateMileStoneStatusFailed());
    }
  } catch (error) {
    yield put(actions.updateMileStoneStatusFailed());
  }
}
export const getTemplate = (store) => store.template;
export function* getFileScreeningStatusSaga(data) {
  const { fileGuid, fileInfo, loadFrom } = data.payload;
  try {
    const response = yield TemplateService.doFileScreeningStatus(fileGuid);
    const { isError, result } = response.data;
    if (!isError) {
      const { status, message, statusmessage } = result;
      if (status === "Imported") {
        const updateFileInfo = {
          ...fileInfo,
          loadFrom,
          loadTo: 25,
          loadStatus: result.status,
          responseData: {
            ...response.data,
          },
        };
        yield put(actions.getFileScreeningStatusSuccess(updateFileInfo));
      } else if (status === "Failed") {
        const updateFileInfo = {
          ...fileInfo,
          loadFrom,
          loadTo: 25,
          responseData: {
            isError: true,
            result: {
              responseException: {
                exceptionMessage: result.statusmessage,
              },
            },
          },
        };
        yield put(actions.getFileScreeningStatusFailed(updateFileInfo));
      } else if (status === "cancelled") {
        const updateFileInfo = {
          ...fileInfo,
          loadFrom,
          loadTo: 25,
          loadStatus: result.status,
          responseData: {
            ...response.data,
          },
        };
        yield put(actions.getFileScreeningStatusSuccess(updateFileInfo));
      } else {
        let updateLoadFromTo = {
          loadFrom: loadFrom,
          loadTo: 12,
        };
        if (result.status === "Scanning In-Progress") {
          updateLoadFromTo = {
            loadFrom: loadFrom,
            loadTo: 18,
          };
        }
        if (result.status === "Import In-Progress") {
          updateLoadFromTo = {
            loadFrom: loadFrom,
            loadTo: 22,
          };
        }
        const updateFileInfo = {
          ...fileInfo,
          ...updateLoadFromTo,
          loadStatus: result.status,
          responseData: {
            ...response.data,
          },
        };
        yield put(actions.getFileScreeningStatusSuccess(updateFileInfo));
        yield delay(5000);
        let { isTemplateCancel } = yield select(getTemplate);
        if (!isTemplateCancel) {
          yield put(
            actions.getFileScreeningStatus(
              fileGuid,
              fileInfo,
              updateLoadFromTo.loadTo
            )
          );
        } else {
          yield put(actions.handleTemplateCancel(false));
        }
      }
    } else {
      const updateFileInfo = {
        ...fileInfo,
        responseData: {
          isError: true,
          result: null,
        },
      };
      yield put(actions.getFileScreeningStatusFailed(updateFileInfo));
    }
  } catch (error) {
    yield put(actions.getFileScreeningStatusFailed(fileInfo));
  }
}

export function* getFileScreeningCancelSaga(data) {
  const { fileGuid, fileInfo, loadFrom } = data.payload;
  try {
    const response = yield fileGuid &&
      TemplateService.doFileScreeningCancel({ fileGuid });
    const { isError, result } = response.data;
    if (!isError) yield put(actions.getFileScreeningStatusSuccess(result));
    else yield put(actions.getFileScreeningStatusFailed());
  } catch (error) {
    yield put(actions.getFileScreeningStatusFailed());
  }
}

export function* getMileStoneCountSaga() {
  try {
    const response = yield DocumentService.getMileStoneCount();
    const { isError } = response.data;
    if (!isError) {
      yield put(
        actions.getMileStoneCountSuccess({
          milestoneCount: response.data.result,
        })
      );
    } else {
      yield put(actions.getMileStoneCountFailed());
    }
  } catch (error) {
    yield put(actions.getMileStoneCountFailed());
  }
}

export function* getMileStoneInfoListSaga() {
  try {
    const response = yield DocumentService.getMileStoneList();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(
        actions.getMileStoneInfoListSuccess(result.viedocmilestonedata)
      );
    } else {
      yield put(actions.getMileStoneInfoListFailed());
    }
  } catch (error) {
    yield put(actions.getMileStoneInfoListFailed());
  }
}

export function* getMilestoneSaga(data) {
  try {
    const response = yield DocumentService.getMileStoneData(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getMileStoneSuccess(result.viedocmilestonedata[0]));
    } else {
      yield put(actions.getMileStoneFailed());
    }
  } catch (error) {
    yield put(actions.getMileStoneFailed());
  }
}

export function* getMileStoneArtifactCountSaga(data) {
  try {
    const response = yield DocumentService.getMileStoneArtifactCount(
      data.payload
    );
    const { isError, result } = response.data;
    if (!isError) {
      yield put(
        actions.getMileStoneArtifactCountSuccess(result.milestoneArtifacts)
      );
    } else {
      yield put(actions.getMileStoneArtifactCountFailed());
    }
  } catch (error) {
    yield put(actions.getMileStoneArtifactCountFailed());
  }
}

export function* getMileStoneGroupSaga() {
  try {
    const response = yield DocumentService.getMileStoneGroup();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getMileStoneGroupSuccess(result));
    } else {
      yield put(actions.getMileStoneGroupFailed());
    }
  } catch (error) {
    yield put(actions.getMileStoneGroupFailed());
  }
}

export function* addNewMilestoneSaga(data) {
  try {
    const response = yield DocumentService.addNewMileStone(data.payload);
    const { isError, result } = response.data;

    if (!isError) {
      const { issuccess, message, existingfields } = result;
      if (issuccess) {
        yield put(actions.addNewMilestoneSuccess(result));
        yield put(actions.getMileStoneInfoListSuccess(result.milestones));
        yield put(actions.getMilestoneCount());
        yield put(actions.getTMFStructureChanges());
      } else {
        yield put(
          actions.addNewMilestoneFailed({
            message,
            existingfields,
            formErrorValues: data.payload,
          })
        );
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.addNewMilestoneFailed({ message: i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD") }));
    }
  } catch (error) {
    yield put(actions.addNewMilestoneFailed());
  }
}

export function* editMilestoneSaga(data) {
  try {
    const response = yield DocumentService.doEditMileStone(data.payload);
    const { isError, result } = response.data;

    if (!isError) {
      const { issuccess, message, existingfields } = result;
      if (issuccess) {
        yield put(actions.editMilestoneSuccess(result));
        yield put(actions.getMileStoneInfoListSuccess(result.milestones));
        yield put(actions.getTMFStructureChanges());
      } else {
        yield put(
          actions.editMilestoneFailed({
            message,
            existingfields,
            formErrorValues: data.payload,
          })
        );
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.editMilestoneFailed(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    }
  } catch (error) {
    //yield put(actions.editMilestoneFailed(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
  }
}

export function* changeMilestoneSequenceSaga(data) {
  try {
    const response = yield DocumentService.changeMileStoneSequence(
      data.payload
    );
    const { isError, result } = response.data;
    const { issuccess, message } = result;

    if (!isError) {
      if (issuccess) {
        yield put(actions.getMileStoneInfoListSuccess(result.milestones));
        yield put(actions.changeMilestoneSequenceSuccess(result));
        yield put(actions.getTMFStructureChanges());
      } else {
        yield put(actions.changeMilestoneSequenceFailed(message));
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.changeMilestoneSequenceFailed(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    }
  } catch (error) {
    yield put(actions.changeMilestoneSequenceFailed(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
  }
}

export function* getMileStoneFilterListSaga() {
  try {
    const response = yield TemplateService.doFetchMileStoneFilterList();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getMileStoneFilterListSuccess(result));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
  }
}

export function* deleteMilestoneSaga(data) {
  try {
    const response = yield DocumentService.doDeleteMileStone(data.payload);
    const { isError, result } = response.data;

    if (!isError) {
      const { issuccess, message } = result;
      if (issuccess) {
        yield put(actions.getMileStoneInfoListSuccess(result.milestones));
        yield put(actions.deleteMilestoneSuccess(result));
        yield put(actions.getMilestoneCount());
        yield put(actions.getTMFStructureChanges());
      } else {
        yield put(actions.deleteMilestoneFailed(message));
      }
    } else {
      //const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.deleteMilestoneFailed(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    }
  } catch (error) {
    yield put(actions.deleteMilestoneFailed());
  }
}

export function* getCompleteAuditTrailSaga() {
  try {
    const response = yield TemplateService.doFetchAuditTrailDetail();
    const { isError, result } = response.data;
    if (!isError) {
      const { live, latest } = result;
      yield put(actions.getCompleteAuditTrailSuccess(latest, _.isEmpty(live)));
      if (!_.isEmpty(live)) {
        const reqPayload = {
          archiveId: live.id,
        };
        yield put(actions.statusCompleteAuditTrail(reqPayload));
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.getCompleteAuditTrailFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(
      actionsLoader.showError(i18n.t("ARCHIVE.TOAST_GENERATESOMETHINGWENTWRONG"))
    );
  }
}

export function* generateCompleteAuditTrailSaga(data) {
  try {
    const response = yield TemplateService.doCompleteAuditTrail(data.payload);
    const { isError, result } = response.data;

    if (!isError) {
      const { archiveId, status } = result;
      if (!_.isEmpty(archiveId)) {
        yield put(actions.statusCompleteAuditTrail(result));
      } else {
        yield put(actions.generateCompleteAuditTrailFailed());
      }
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.generateCompleteAuditTrailFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(
      actionsLoader.showError(i18n.t("ARCHIVE.TOAST_GENERATESOMETHINGWENTWRONG"))
    );
  }
}

export function* statusCompleteAuditTrailSaga(data) {
  const { archiveId } = data.payload;
  try {
    if (!_.isEmpty(archiveId)) {
      const response = yield TemplateService.doFetchStatusAuditTrail(archiveId);
      const { isError, result } = response.data;
      if (!isError) {
        yield put(
          actions.generateCompleteAuditTrailSuccess({
            ...data.payload,
            ...result,
          })
        );
      } else {
        const errorMessage = UtilsHelper.constructErrorMsg(response.data);
        yield put(actions.generateCompleteAuditTrailFailed(errorMessage));
        yield put(actionsLoader.showError(errorMessage));
      }
    }
  } catch (error) {
    yield put(
      actionsLoader.showError(i18n.t("ARCHIVE.TOAST_GENERATESOMETHINGWENTWRONG"))
    );
  }
}

export function* cancelCompleteAuditTrailSaga(data) {
  try {
    const response = yield TemplateService.doCancelAuditTrail(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      if (data.isStatus) yield put(actions.statusCompleteAuditTrail(result));
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.generateCompleteAuditTrailFailed(errorMessage));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(
      actionsLoader.showError(i18n.t("ARCHIVE.TOAST_GENERATESOMETHINGWENTWRONG"))
    );
  }
}
export function* downloadCompleteAuditTrailSaga(data) {
  try {
    let validFilename = UtilsHelper.fileInvalidCharsRemoval(data.payload.filename);
    data.payload.friendlyFileName = validFilename;
    const response = yield TemplateService.doDownloadAuditTrail(data.payload);
    const { isError, result } = response.data;

    if (!isError) {
      let fileurl = atob(result.sastoken);
      const { filename } = data.payload;
      yield AzureFileuploadHelper.downloadAzureFile(fileurl, {
        downloadFileName: UtilsHelper.fileExtensionRemoval(filename),
        type: UtilsHelper.getFileExtension(filename),
      });
      yield put(actions.downloadCompleteAuditTrailSuccess(data.payload.id));
      // yield AzureFileuploadHelper.downloadAzureFile(fileurl);
    } else {
      const errorMessage = UtilsHelper.constructErrorMsg(response.data);
      yield put(actions.downloadCompleteAuditTrailFailed(errorMessage, data.payload.id));
      yield put(actionsLoader.showError(errorMessage));
    }
  } catch (error) {
    yield put(
      actionsLoader.showError(i18n.t("ARCHIVE.TOAST_GENERATESOMETHINGWENTWRONG"))
    );
  }
}

export function* getDropZoneSettingsSaga() {
  try {
    const response = yield TemplateService.getDropZoneSettings();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(
        actions.getDropZoneSettingsSuccess(result)
      );
    } else {
      yield put(actions.getDropZoneSettingsFailed());
    }
  } catch (error) {
    yield put(actions.getDropZoneSettingsFailed());
  }
}
export function* getDropZoneFileScreeningStatusSaga(data) {
  const { fileGuid, fileInfo, loadFrom, viewPerPageCount } = data.payload;
  try {
    const response = yield TemplateService.getDropZoneFileScreening(fileGuid);
    const { isError, result } = response.data;
    const { status, message, statusmessage, dropzoneresult } = result;
    if (!isError && result.status !== "Failed") {
      if (status !== "Imported" && status !== "cancelled") {
        fileInfo.createdon = result.importedon;
        fileInfo.createdby = result.importedby;
        yield put(
          actions.resetProgressFileData(fileInfo, status)
        )
        yield delay(3000);
        yield put(
          actions.getDropZoneFileScreeningStatus(
            fileGuid,
            fileInfo,
          )
        );
      }

      if (status === "Imported") {
        let reqPayload = {
          fileGuid: fileInfo.guid,
          category: fileInfo.category,
          fileName: fileInfo.filename,
          fileExtension: fileInfo.fileextension,
          sizeInBytes: fileInfo.size,
          azureStorageResponse: JSON.stringify(fileInfo.azureData)
        }
        yield put(
          actions.resetProgressFileData(fileInfo, status)
        );
        let lastdocumentupdatedon = sessionStorage.getItem("lastdocumentupdatedon");
        let req = {
          searchKey: "",
          site: "ALL",
          countryCode: "ALL",
          pageCount: 0,
          pageSize: viewPerPageCount || 20000,
          pageNumber: 1,
          artifactId: "",
          sectionid: "",
          category: "",
          milestoneGroup: "ALL",
          milestoneId: "",
          lastdocumentupdatedon: lastdocumentupdatedon
        };
        let recentSearchInfo = StorageHelper.getRecentSearchInfo();
        if (!_.isEmpty(recentSearchInfo)) {
          StorageHelper.setRecentSearchInfo(recentSearchInfo);
        } else {
          StorageHelper.setRecentSearchInfo(req);
        }
        let isFileScan = StorageHelper.getIsAGGridApiRunning();
        if (isFileScan == "false") {
          StorageHelper.setIsAGGridApiRunning(true);
          yield put(zoneActions.fetchAllAggridData(req, false));
        }
        yield put(actions.doSaveTMFDropZoneSuccess(dropzoneresult));
        yield put(actions.getDropZoneSettings());
      }
    } else {
      if (status === "Failed") {
        if (statusmessage === "Virus is Found") {
          yield put(actions.resetProgressFileData(fileInfo, "cancelled"));
          fileInfo.comments = i18n.t("TMF.TOAST_FILECONTAINVIRUSMESSAGE");
          yield put(actions.setInvalidError(fileInfo));
          yield put(actions.getDropZoneFileScreeningStatusFailed(fileInfo));
        }
      }
    }
  } catch (error) {
    yield put(actions.getDropZoneFileScreeningStatusFailed(fileInfo));
  }
}
export function* getDropZoneFileScreeningCancelSaga(data) {
  const fileGuid = data.payload.guid;
  try {
    const response = yield fileGuid &&
      TemplateService.doDropZoneFileScreeningCancel({ fileGuid });
  } catch (error) {
    yield put(actions.getDropZoneFileScreeningStatusFailed());
  }
}
export function* fetchDropZoneDocumentsSaga(data) {
  try {
    const response = yield TemplateService.getDropZoneDocuments(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(
        actions.fetchDropZoneDocumentsSuccess(result)
      );
    } else {
      yield put(actions.fetchDropZoneDocumentsFailed());
    }
  } catch (error) {
    yield put(actions.fetchDropZoneDocumentsFailed());
  }
}
export function* doSaveTMFDropZoneSaga(data) {
  try {
    const response = yield TemplateService.doSaveTMFDropZone(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.doSaveTMFDropZoneSuccess(result));
      yield put(actions.getDropZoneSettings());
    } else {
      yield put(actions.fetchDropZoneDocumentsFailed());
    }
  } catch (error) {
    yield put(actions.fetchDropZoneDocumentsFailed());
  }
}
export function* deleteDropZoneSaga(data) {
  try {
    yield put(actionsLoader.showLoader());
    const response = yield TemplateService.deleteDropZoneDocument(data.payload.id);
    const { isError, result } = response;
    if (!isError && response.status == '200') {
      yield put(actions.getDropZoneSettings());
      yield put(actions.removeDeletedDoc(data.payload));
      yield put(actionsLoader.hideLoader());
      const recentSearchInfo = StorageHelper.getRecentSearchInfo();
      if (!_.isEmpty(recentSearchInfo) && data.payload.searchView === true) {
        yield put(zoneActions.fetchSearchResult(recentSearchInfo));
      }

      let lastdocumentupdatedon = JSON.parse(sessionStorage.getItem("lastdocumentupdatedon"));
      let req = {
        searchKey: "",
        site: "ALL",
        countryCode: "ALL",
        pageCount: 0,
        pageSize: 20000,
        pageNumber: 1,
        artifactId: "",
        sectionid: "",
        category: "",
        milestoneGroup: "ALL",
        milestoneId: "",
        lastdocumentupdatedon: lastdocumentupdatedon
      };
      if (!_.isEmpty(recentSearchInfo)) {
        StorageHelper.setRecentSearchInfo(recentSearchInfo);
      } else {
        StorageHelper.setRecentSearchInfo(req);
      }
      let currentView = (data.payload.searchView === true) ? false : true;
      let isFileScan = StorageHelper.getIsAGGridApiRunning();
      if (isFileScan == "false") {
        StorageHelper.setIsAGGridApiRunning(true);
        yield put(zoneActions.fetchAllAggridData(req, currentView));
      }

    } else {
      yield put(actions.fetchDropZoneDocumentsFailed());
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(actions.fetchDropZoneDocumentsFailed());
  }
}
export function* doDropZoneFileScreeningSaga(data) {
  const { requestPayload, guid, documentData, index, viewPerPageCount } = data.payload;
  try {
    const fileScreening = yield TemplateService.doDropZoneFileScreening(requestPayload);
    const { isError, result } = fileScreening.data;
    const fileInfo = documentData;
    if (!isError) {
      yield put(actions.getDropZoneFileScreeningStatus(guid, documentData, index, viewPerPageCount));
    } else {
      yield put(actions.resetProgressFileData(fileInfo, "cancelled"));
      documentData.comments = i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD");
      yield put(actions.setInvalidError(documentData));
    }
  } catch (error) {
  }
}
export function* getLockStatusSaga() {
  try {
    const response = yield TemplateService.getEtmfLockStatus();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getLockStatusSuccess(result));
    } else {
      yield put(actions.getLockStatusFailed());
    }
  } catch (error) {
    yield put(actions.getLockStatusFailed());
  }
}
export function* setetmflock(data) {
  try {
    const response = yield TemplateService.setetmflock(data.payload);
    const { isError, result } = response.data;
    const { issuccess } = result;
    if (!isError) {
      if (issuccess) {
        yield put(actions.setETMFLockSuccess(result));
      } else {
        yield put(actionsLoader.showError(i18n.t("ADMIN.TOAST_LOCKORUNLOCKFAILED")));
        yield put(actions.setETMFLockFailed());
      }
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.setETMFLockFailed());
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    yield put(actions.setETMFLockFailed());
  }
}
export function* setetmfunlock(data) {
  try {
    const response = yield TemplateService.setetmfunlock(data.payload);
    const { isError, result } = response.data;
    const { issuccess } = result;
    if (!isError) {
      if (issuccess) {
        yield put(actions.setETMFUnLockSuccess(result));
      } else {
        yield put(actionsLoader.showError(i18n.t("ADMIN.TOAST_LOCKORUNLOCKFAILED")));
        yield put(actions.setETMFUnLockFailed());
      }
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.setETMFUnLockFailed());
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    yield put(actions.setETMFUnLockFailed());
  }
}
export function* getdocumentTemplateNamesSaga(data) {
  try {
    const response = yield TemplateService.getDocumentTemplateNames(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getDocumentTemplateNamesSuccess(result))
    } else {
      yield put(actions.getDocumentTemplateNamesFailed())
    }
  } catch (error) {
    yield put(actions.getDocumentTemplateNamesFailed())
  }
}
export function* getAgGridDataSaga() {
  try {
    const response = yield TemplateService.getAgGridData();
    const { isError, result } = response.data;
    //console.log("sagress", result)
    if (!isError) {
      yield put(actions.getAgGridDataSuccess(result))
    } else {
      yield put(actions.getAgGridDataFailed())
    }
  } catch (error) {
    yield put(actions.getAgGridDataFailed())
  }
}
export function* getTimeFormatSaga() {
  try {
    const response = yield TemplateService.doGetTimeFormat();
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getTimeFormatSuccess(result));
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
    }
  } catch (error) {
    yield put(actionsLoader.showError(i18n.t("TMF.TOAST_SOMETHINGWENTWRONG")));
  }
}
export function* getSharedDocument(data) {
  try {
    let reqData = data.payload;
    const response = yield TemplateService.getSharedDocument(reqData);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.getSharedDocumentSuccess(result))
    } else {
      yield put(actions.getSharedDocumentFailed())
    }
  } catch (error) {
    yield put(actions.getSharedDocumentFailed())
  }
}

export function* saveCustomizedColState(data) {
  try {
    let reqData = {
      "UserPreference": data.payload.UserPreference,
      "FilterPreference": data.payload.FilterPreference
    };
    const response = yield TemplateService.saveCustomizedColState(reqData);
    const { isError, result } = response.data;
    if (!isError) {
      sessionStorage.setItem("customizedColState", data.payload.UserPreference);
      sessionStorage.setItem("customizedFilterState", data.payload.FilterPreference);
      yield delay(2000);
      yield put(actions.saveCustomizedColumStateSuccess(result));
    } else {
      yield put(actionsLoader.showError(i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actions.saveCustomizedColumStateFailed())
    }
  } catch (error) {
    yield put(actions.saveCustomizedColumStateFailed())
  }
}

export function* getCustomizedColState(data) {
  try {
    let reqData = data.payload;
    const response = yield TemplateService.getCustomizedColState(reqData);
    const { isError, result } = response.data;
    //console.log("cussss",result )
    if (!isError) {
      yield put(actions.getCustomizedColumStateSuccess(result))
    } else {
      yield put(actions.getCustomizedColumStateFailed())
    }
  } catch (error) {
    yield put(actions.getCustomizedColumStateFailed())
  }
}
export function* cloneNewVersionSaga(data) {
  try {
    const response = yield TemplateService.cloneDocNewVersion(data.payload);
    const { isError, result } = response.data;
    if (!isError) {
      yield put(actions.cloneDocNewVersionSuccess(result));
      yield put(documentActions.getSelectedDocumentDetailSuccess(result.documentdetail));
      yield put(documentActions.getDocNoteSuccess([]));
      yield put(documentActions.doGetDocumentVersionListSuccess(result.documentversionlist));
      yield put(actionsLoader.hideLoader());
    } else {
      yield put(dialogActions.showViewDocDialog("", i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
      yield put(actionsLoader.hideLoader());
    }
  } catch (error) {
    yield put(dialogActions.showViewDocDialog("", i18n.t("TMF.TOAST_DOCUPDATEFAILEDREFRESHRELOAD")));
    yield put(actions.cloneDocNewVersionFailed())
    yield put(actionsLoader.hideLoader());
  }
}

export function* watchTemplateAsync() {
  yield takeEvery(actionTypes.FETCH_TEMPLATE, fetchTemplate);
  yield takeEvery(actionTypes.FETCH_CONFIGURATIONS, fetchConfiguration);
  yield takeEvery(actionTypes.TMF_STRUCTURE_DATA, getTMFStructureDataSaga);
  yield takeEvery(actionTypes.FETCH_ALL_TEMPLATES, getAllTemplatesSaga);
  yield takeEvery(actionTypes.SELECT_TEMPLATE, selectTemplateSaga);
  yield takeEvery(actionTypes.LOGGEDIN_USER, getLoggedInUser);
  yield takeEvery(actionTypes.STUDY_INFO, getStudyInfoSaga);
  yield takeEvery(actionTypes.INSTANTIATE_TEMPLATE, instantiateTemplateSaga);
  yield takeEvery(
    actionTypes.GET_VIEW_ZONE_SEC_ARTIF,
    getViewZoneSectionArtifactSaga
  );
  yield takeEvery(actionTypes.EXPORT_TEMPLATE, exportTemplateSaga);
  yield takeEvery(actionTypes.DELETE_TEMPLATE, deleteTemplate);
  yield takeEvery(actionTypes.GET_ETMF_ARCHIVING, getEtmfArchivingSaga);
  yield takeEvery(actionTypes.EXPORT_ETMF_DOCUMENTS, exportEtmfDocumentsSaga);
  yield takeEvery(
    actionTypes.DOWNLOAD_ETMF_DOCUMENT,
    downloadEtmfDocumentsSaga
  );
  yield takeEvery(
    `${actionTypes.EXPORT_ETMF_DOCUMENTS}_CANCEL`,
    cancelEtmfDocumentsSaga
  );
  yield takeEvery(
    `${actionTypes.EXPORT_ETMF_DOCUMENTS}_WATCH`,
    watchEtmfDocumentsSaga
  );
  yield takeEvery(actionTypes.MAINTENANCE_ON_OFF, maintenanceOnOffSaga);
  yield takeEvery(actionTypes.MAINTENANCE_STATUS, maintenanceStatusSaga);
  yield takeEvery(actionTypes.EDIT_ZONE, editZoneSaga);
  yield takeEvery(actionTypes.EDIT_SECTION, editSectionSaga);
  yield takeEvery(actionTypes.ADD_NEW_ZONE, addNewZoneSaga);
  yield takeEvery(actionTypes.RENAME_TEMPLATE, renameTemplateSaga);
  yield takeEvery(actionTypes.DELETE, deleteStructure);
  yield takeEvery(actionTypes.ADD_NEW_ARTIFACT, addNewArtifactSaga);
  yield takeEvery(actionTypes.GET_MILESTONE_LIST, getMileStoneListSaga);
  yield takeEvery(
    actionTypes.GET_DATING_CONVENTION_LIST,
    getDatingConventionListSaga
  );
  yield takeEvery(
    actionTypes.GET_TMF_STRUCTURE_CHANGES,
    getTMFStructureChangesSaga
  );
  yield takeEvery(
    actionTypes.REVERT_STRUCTURE_CHANGES,
    revertStructureChangeSaga
  );
  yield takeEvery(
    actionTypes.APPLY_STRUCTURE_CHANGES,
    applyStructureChangeSaga
  );
  yield takeEvery(
    actionTypes.GET_ENABLING_MILESTONE_STATUS,
    getEnablingMilestoneStatusSaga
  );
  yield takeEvery(
    actionTypes.UPDATING_ENABLING_MILESTONE_STATUS,
    updatingEnablingMilestoneStatusSaga
  );
  yield takeEvery(
    actionTypes.TEMPLATE_FILE_SCREENING_STATUS,
    getFileScreeningStatusSaga
  );
  yield takeEvery(
    actionTypes.GET_MILESTONE_INFO_LIST,
    getMileStoneInfoListSaga
  );
  yield takeEvery(actionTypes.GET_MILESTONE, getMilestoneSaga);
  yield takeEvery(actionTypes.GET_MILESTONE_COUNT, getMileStoneCountSaga);
  yield takeEvery(
    actionTypes.GET_MILESTONE_ARTIFACT_COUNT,
    getMileStoneArtifactCountSaga
  );
  yield takeEvery(actionTypes.GET_MILESTONE_GROUP, getMileStoneGroupSaga);
  yield takeEvery(actionTypes.ADD_NEW_MILESTONE, addNewMilestoneSaga);
  yield takeEvery(actionTypes.EDIT_MILESTONE, editMilestoneSaga);
  yield takeEvery(
    actionTypes.CHANGE_MILESTONE_SEQUENCE,
    changeMilestoneSequenceSaga
  );
  yield takeEvery(
    actionTypes.GET_MILESTONE_FILTER_LIST,
    getMileStoneFilterListSaga
  );
  yield takeEvery(actionTypes.DELETE_MILESTONE, deleteMilestoneSaga);
  yield takeEvery(
    actionTypes.GET_COMPLETE_AUDIT_TRAIL,
    getCompleteAuditTrailSaga
  );
  yield takeEvery(
    actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL,
    generateCompleteAuditTrailSaga
  );
  yield takeEvery(
    `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_STATUS`,
    statusCompleteAuditTrailSaga
  );
  yield takeEvery(
    `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_CANCEL`,
    cancelCompleteAuditTrailSaga
  );
  yield takeEvery(
    actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL,
    downloadCompleteAuditTrailSaga
  );
  yield takeEvery(
    `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_CANCEL`,
    getFileScreeningCancelSaga
  );
  yield takeEvery(
    `${actionTypes.GET_DROP_ZONE_SETTING}`,
    getDropZoneSettingsSaga
  );
  yield takeEvery(
    actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS,
    getDropZoneFileScreeningStatusSaga
  );
  yield takeEvery(
    `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_CANCEL`,
    getDropZoneFileScreeningCancelSaga
  );
  yield takeEvery(
    `${actionTypes.DO_DROP_ZONE_FILESCREENING_STATUS}`,
    doDropZoneFileScreeningSaga
  );
  yield takeEvery(actionTypes.GET_DROP_ZONE_DATA, fetchDropZoneDocumentsSaga);
  yield takeEvery(actionTypes.DELETE_DROPZONE, deleteDropZoneSaga);
  yield takeEvery(actionTypes.GET_LOCK_STATUS, getLockStatusSaga);
  yield takeEvery(actionTypes.SET_ETMF_LOCK, setetmflock);
  yield takeEvery(actionTypes.SET_ETMF_UNLOCK, setetmfunlock);
  yield takeEvery(actionTypes.GET_DOCU_TEMPLATE_NAMES, getdocumentTemplateNamesSaga);
  yield takeEvery(actionTypes.GET_AGGRID_DATA, getAgGridDataSaga);
  yield takeEvery(actionTypes.GET_SHARED_DOCUMENT, getSharedDocument);
  yield takeEvery(actionTypes.SAVE_CUSTOMIZED_COLSTATE, saveCustomizedColState);
  yield takeEvery(actionTypes.GET_CUSTOMIZED_COLSTATE, getCustomizedColState);
  yield takeEvery(actionTypes.GET_TIME_FORMAT, getTimeFormatSaga);
  yield takeEvery(actionTypes.CLONE_DOC_NEW_VERSION, cloneNewVersionSaga);

}
