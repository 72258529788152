import * as actionTypes from "app/store/actions/actionTypes";
import { updateObject } from "app/store/storeUtils";
import _ from "underscore";
import { setArtifactPaginationInfo } from "../actions/zoneActions";
import { UtilsHelper } from "app/core/utilsHelper";
import { storageFactory } from "app/core/storageFactory";

const initialState = {
  templateImport: {},
  configuration: {},
  templateImportFail: {},
  loggedInUser: {},
  activeAdminTab: "TMF structure",
  tmfStructureData: {},
  zoneData: [],
  orgTemplates: [],
  studyTemplates: [],
  viedocTemplates: [],
  selectedTempID: "",
  isLoading: false,
  selectedLoading: false,
  loadingTempID: "",
  instantiateLoading: false,
  viewDialog: {
    open: false,
    from: "",
    zoneData: {},
    sectionData: {},
    artifactData: {},
  },
  exportingList: [],
  deletedTempID: "",
  eTMFArchivingLoading: false,
  isExporting: false,
  archiveErrStatus: "",
  eTMFArchiveTab: {},
  templateImporting: "",
  maintenanceStatus: "",
  mileStoneList: [],
  datingConventionList: [],
  artifactFail: "",
  isEditLoading: false,
  rawDatingConventionList: [],
  changeditems: "",
  ischangefound: false,
  revertChange: {},
  applyChange: {},
  isChangeLoading: false,
  fileScreeningStatus: {},
  mileStoneInfoList: {},
  viewMilestoneListLoading: false,
  isViewMilestoneList: false,
  mileStoneObjInfo: {},
  milestoneCount: 0,
  isMilestoneViewLoading: false,
  isSingleMilestoneView: false,
  artifactCount: 0,
  artifactList: [],
  mileStoneListWithGroup: [],
  milestoneGroup: [],
  mileStoneLastModified: "",
  milestoneActionFail: "",
  isArtifactEditSuccess: false,
  isMilestoneChanged: false,
  errorFields: [],
  formErrorValues: {},
  isArchiveLoading: false,
  latestArchiveInfo: [],
  isGeneratingAuditTrail: false,
  generateAuditTrailInfo: {},
  isCancelBeforeSuccess: false,
  isCancelLoading: false,
  settings: {
    isMilestoneEnabled: false,
    isAutoLockEnabled: false,
    isReasonForUnlockEnabled: false,
    isshareddropzoneenabled: {},
    isprivatedropzoneenabled: {},
    istmflevelenabled: false,
    isaggridincluded: false,
    displaytemplate: "",
    downloadtemplate: "",
    archivetemplate: "",
    showstructurenumber: false
  },
  sharedenabled: true,
  privateenabled: false,
  sharedfilecount: 1,
  privatefilecount: 1,
  dropZoneFileScreeningStatus: {},
  isDropZoneCancelBeforeSuccess: false,
  progressDropZoneFiles: [],
  invalidDoc: [],
  dropZoneDoc: [],
  activeDropzone: "",
  downloadingFiles: [],
  lockStatus: "",
  isEtmfLocked: false,
  isLockLoading: false,
  agGridFrom: "",
  customizedColState: "undefined",
  customizedFilterState: "undefined",
  lastMode: "",
  presentMode: "",
  isSearchHappend: false,
  isCloseHappend: false,
  isTemplateCancel: false,
  datePattern: "",
  fullDateTimePattern: "",
  dateLocale: "en-us",
  isNewDocVersionCreated: false,
  activeDocID: null,
  isDocNewVersionLoading: false,
  deatilspagecontentseparate: " "
};
const localStore = storageFactory(() => sessionStorage);
export const setIndexDropZone = (dropZoneFiles, activeDropzone) => {
  const get = JSON.parse(localStore.getItem(activeDropzone));
  let newArray = [];
  for (let i = 0; i < dropZoneFiles.length; i++) {
    let findIndex = get.findIndex(file => (file.guid || file.fileguid) === (dropZoneFiles[i].guid || dropZoneFiles[i].fileguid));
    newArray[findIndex] = dropZoneFiles[i];
  }
  newArray = newArray.filter(file => file !== {});
  return newArray;
};
export const templateReducer = (
  state = initialState,
  action = { type: "" }
) => {
  switch (action.type) {
    case actionTypes.TEMPLATE_IMPORT:
      return updateObject(state, { templateImporting: action.from });
    case actionTypes.TEMPLATE_IMPORT_SUCCESS: {
      let orgTemplatesImport = [...state.orgTemplates];
      let studyTemplatesImport = [...state.studyTemplates];
      let viedocTemplatesImport = [...state.viedocTemplates];
      const templateCategory =
        action.payload.category || action.payload.templatecategory;
      switch (templateCategory) {
        case "Organization template":
          orgTemplatesImport.unshift(action.payload);
          break;
        case "Study template":
          studyTemplatesImport.unshift(action.payload);
          break;
        default:
          viedocTemplatesImport.unshift(action.payload);
          break;
      }
      return {
        ...state,
        templateImporting: "",
        templateImport: action.payload,
        templateImportFail: {},
        orgTemplates: orgTemplatesImport,
        studyTemplates: studyTemplatesImport,
        viedocTemplates: viedocTemplatesImport,
      };
    }
    case actionTypes.TEMPLATE_IMPORT_FAILED:
      return updateObject(state, {
        templateImporting: "",
        templateImportFail: action.payload,
      });
    case actionTypes.FETCH_TEMPLATE_SUCCESS:
      return updateObject(state, {
        templateImport: action.payload,
        templateImportFail: {},
      });
    case actionTypes.FETCH_CONFIGURATIONS_SUCCESS:
      return updateObject(state, { configuration: action.payload });
    case actionTypes.ACTIVE_ADMIN_TAB:
      return updateObject(state, { activeAdminTab: action.payload });
    case `${actionTypes.STUDY_INFO}_FAILED`:
      return updateObject(state, { loggedInUser: {} });
    case `${actionTypes.STUDY_INFO}_SUCCESS`:
      return updateObject(state, { loggedInUser: action.payload });
    case actionTypes.TMF_STRUCTURE_DATA: {
      const loadingObj =
        action.payload === "onload"
          ? { isLoading: true }
          : { instantiateLoading: true };
      return updateObject(state, { ...loadingObj });
    }
    case `${actionTypes.TMF_STRUCTURE_DATA}_FAILED`:
      return updateObject(state, {
        isLoading: false,
        instantiateLoading: false,
      });
    case `${actionTypes.TMF_STRUCTURE_DATA}_SUCCESS`:
      const { result } = action.payload;
      let updatedZoneList = result.zonedata || [];
      return updateObject(state, {
        tmfStructureData: result,
        zoneData: updatedZoneList,
        isLoading: false,
        instantiateLoading: false,
      });
    case actionTypes.FETCH_ALL_TEMPLATES:
      return updateObject(state, {
        isLoading: true,
        orgTemplates: [],
        studyTemplates: [],
        viedocTemplates: [],
        fileScreeningStatus: {},
      });
    case `${actionTypes.FETCH_ALL_TEMPLATES}_FAILED`:
      return updateObject(state, { isLoading: false });
    case `${actionTypes.FETCH_ALL_TEMPLATES}_SUCCESS`: {
      const allTemplates = action.payload;
      const groupedTemplates = _.groupBy(allTemplates, "templatecategory");
      const orgTemplates = groupedTemplates["Organization template"] || [];
      const studyTemplates = groupedTemplates["Study template"] || [];
      const viedocTemplates = groupedTemplates["Viedoc System Template"] || [];

      const originalTemplateId = state.tmfStructureData.originaltemplateid;
      const findSelected = allTemplates.find(
        (obj) => obj.templateid === originalTemplateId
      );
      return {
        ...state,
        orgTemplates,
        studyTemplates,
        viedocTemplates,
        selectedTempID: (findSelected && findSelected.templateid) || "",
        isLoading: false,
      };
    }
    case actionTypes.SELECT_TEMPLATE:
      return updateObject(state, {
        selectedLoading: true,
        loadingTempID: action.payload.templateId,
      });
    case `${actionTypes.SELECT_TEMPLATE}_FAILED`:
      return updateObject(state, { selectedLoading: false, loadingTempID: "" });
    case `${actionTypes.SET_LAST_MODE}`:
      return updateObject(state, { lastMode: action.payload });
    case `${actionTypes.SET_CURRENT_MODE}`:
      return updateObject(state, { presentMode: action.payload });
    case `${actionTypes.GET_TIME_FORMAT}_SUCCESS`:
      let datePattern = !_.isEmpty(action.payload.datePattern) ? action.payload.datePattern : "";
      let fullDateTimePattern = !_.isEmpty(action.payload.fullDateTimePattern) ? action.payload.fullDateTimePattern : "";
      let deatilspagecontentseparate = !_.isEmpty(action.payload.deatilspagecontentseparate) ? action.payload.deatilspagecontentseparate : "  ";
      return updateObject(state, {
        datePattern: datePattern,
        fullDateTimePattern: fullDateTimePattern,
        dateLocale: action.payload.regionformat,
        deatilspagecontentseparate: deatilspagecontentseparate
      });
    case `${actionTypes.IS_SEARCH_HAPPEND}`:
      return updateObject(state, { isSearchHappend: action.payload });
    case `${actionTypes.IS_CLOSE_HAPPEND}`:
      return updateObject(state, { isCloseHappend: action.payload });
    case `${actionTypes.SELECT_TEMPLATE}_SUCCESS`: {
      const selectedTemplate = action.payload && action.payload.result;
      return updateObject(state, {
        selectedLoading: false,
        loadingTempID: "",
        selectedTempID: selectedTemplate.templateid,
      });
    }
    case actionTypes.INSTANTIATE_TEMPLATE:
      return updateObject(state, { instantiateLoading: true });
    case `${actionTypes.INSTANTIATE_TEMPLATE}_SUCCESS`:
      return updateObject(state, { instantiateLoading: false });
    case `${actionTypes.INSTANTIATE_TEMPLATE}_FAILED`:
      return updateObject(state, { instantiateLoading: false });
    case actionTypes.GET_VIEW_ZONE_SEC_ARTIF:
      const updatedViewData = state.viewDialog;
      updatedViewData.from = action.payload && action.payload.from;
      updatedViewData.mode = action.payload && action.payload.mode;
      updatedViewData.open = true;
      return updateObject(state, {
        viewLoading: true,
        viewDialog: updatedViewData,
      });
    case `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_CLOSE`: {
      const closedViewData = state.viewDialog;
      closedViewData.open = false;
      return updateObject(state, {
        viewLoading: false,
        viewDialog: closedViewData,
      });
    }
    case `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_SUCCESS`: {
      const { from } = action.payload;
      const updatedViewDialog = state.viewDialog;
      switch (from) {
        case "zone":
          updatedViewDialog.zoneData = action.payload;
          break;
        case "section":
          updatedViewDialog.sectionData = action.payload;
          break;
        case "artifact":
          updatedViewDialog.artifactData = action.payload;
          break;
        default:
          break;
      }
      return updateObject(state, {
        viewLoading: false,
        viewDialog: updatedViewDialog,
      });
    }
    case `${actionTypes.GET_VIEW_ZONE_SEC_ARTIF}_FAILED`:
      return updateObject(state, { viewLoading: false });
    case `${actionTypes.EXPORT_TEMPLATE}`: {
      const exportTemplate = action.payload;
      let updateExportingList = [...state.exportingList];
      updateExportingList.push(exportTemplate.templateid);
      return updateObject(state, { exportingList: updateExportingList });
    }
    case `${actionTypes.EXPORT_TEMPLATE}_FAILED`: {
      const failedExportingList = _.filter(
        [...state.exportingList],
        (obj) => obj !== action.payload
      );
      return updateObject(state, { exportingList: failedExportingList });
    }
    case `${actionTypes.EXPORT_TEMPLATE}_SUCCESS`: {
      const successExportingList = _.filter(
        [...state.exportingList],
        (obj) => obj !== action.payload
      );
      return updateObject(state, { exportingList: successExportingList });
    }
    case `${actionTypes.DELETE_TEMPLATE}_SUCCESS`: {
      const deletedTemplateData = action.payload;

      let updatedOrgTemplates = [...state.orgTemplates];
      let updatedStudyTemplates = [...state.studyTemplates];
      let updatedViedocTemplates = [...state.viedocTemplates];

      let findIndex;
      switch (deletedTemplateData.templatecategory) {
        case "Organization template": {
          findIndex = _.findIndex(updatedOrgTemplates, {
            templateid: deletedTemplateData.templateid,
          });
          if (findIndex !== -1) {
            updatedOrgTemplates.splice(findIndex, 1);
          }
          return updateObject(state, {
            orgTemplates: updatedOrgTemplates,
          });
        }
        case "Study template": {
          findIndex = _.findIndex(updatedStudyTemplates, {
            templateid: deletedTemplateData.templateid,
          });
          if (findIndex !== -1) {
            updatedStudyTemplates.splice(findIndex, 1);
          }
          return updateObject(state, {
            studyTemplates: updatedStudyTemplates,
          });
        }
        case "Viedoc System Template": {
          findIndex = _.findIndex(updatedViedocTemplates, {
            templateid: deletedTemplateData.templateid,
          });
          if (findIndex !== -1) {
            updatedViedocTemplates.splice(findIndex, 1);
          }
          return updateObject(state, {
            viedocTemplates: updatedViedocTemplates,
          });
        }
        default:
          return state;
      }
    }
    case actionTypes.GET_ETMF_ARCHIVING:
      return updateObject(state, { eTMFArchivingLoading: true });
    case `${actionTypes.GET_ETMF_ARCHIVING}_FAILED`:
      return updateObject(state, { eTMFArchivingLoading: false });
    case `${actionTypes.GET_ETMF_ARCHIVING}_SUCCESS`: {
      const etmfValues = action.payload && action.payload.data.result;
      const exportState =
        etmfValues.latestarchive && etmfValues.latestarchive.status;
      if (exportState === "INPROGRESS") {
        return updateObject(state, {
          eTMFArchivingLoading: false,
          eTMFArchiveTab: etmfValues,
        });
      }
      return updateObject(state, {
        eTMFArchivingLoading: false,
        eTMFArchiveTab: etmfValues,
      });
    }
    case actionTypes.EXPORT_ETMF_DOCUMENTS:
      return updateObject(state, { isExporting: true });
    case `${actionTypes.EXPORT_ETMF_DOCUMENTS}_FAILED`:
      return updateObject(state, { isExporting: false });
    case `${actionTypes.EXPORT_ETMF_DOCUMENTS}_SUCCESS`: {
      const { exportFrom, status } = action.payload;
      let archiveErr = "";
      if (status === "ERROR") {
        archiveErr = "ERROR";
      }
      const statusArr = ["finished", "failed", "cancel"];
      // let updatedValue;
      // if (statusArr.includes(exportFrom)) updatedValue = false;
      // else updatedValue = true;
      return updateObject(state, {
        isExporting: !statusArr.includes(exportFrom),
        archiveErrStatus: archiveErr,
      });
    }
    case `${actionTypes.EXPORT_ETMF_DOCUMENTS}_CLOSE`:
      return updateObject(state, { archiveErrStatus: "" });
    case actionTypes.DOWNLOAD_ETMF_DOCUMENT:
      return updateObject(state, {});
    case `${actionTypes.DOWNLOAD_ETMF_DOCUMENT}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.DOWNLOAD_ETMF_DOCUMENT}_SUCCESS`:
      return updateObject(state, {});
    case actionTypes.MAINTENANCE_ON_OFF:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.MAINTENANCE_ON_OFF}_FAILED`:
      return updateObject(state, { isEditLoading: false });
    case `${actionTypes.MAINTENANCE_ON_OFF}_SUCCESS`:
      const updateTMFStructure = {
        ...state.tmfStructureData,
        ...action.payload,
      };
      return updateObject(state, {
        tmfStructureData: updateTMFStructure,
      });
    case `${actionTypes.MAINTENANCE_STATUS}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.MAINTENANCE_STATUS}_SUCCESS`:
      return updateObject(state, {
        maintenanceStatus: action.payload,
      });
    case `${actionTypes.ADD_NEW_ZONE}_SUCCESS`: {
      const { zonedata } = action.payload;
      const zoneListItems = [...state.tmfStructureData.zonedata];
      zoneListItems.push({ ...zonedata });
      const tmf = {
        ...state.tmfStructureData,
        zonedata: zoneListItems,
      };
      return updateObject(state, {
        tmfStructureData: tmf,
      });
    }
    case `${actionTypes.EDIT_ZONE}`:
      return updateObject(state, { editError: "", isEditLoading: true });
    case `${actionTypes.EDIT_ZONE}_SUCCESS`:
      const editZoneObj = action.payload;
      const updateZoneList = state.zoneData.map((obj) => {
        let returnZoneObj = obj;
        returnZoneObj["recentModifiedOn"] = editZoneObj.lastmodifiedon;
        if (obj.origid === editZoneObj.zoneid) {
          returnZoneObj = {
            ...obj,
            ...editZoneObj,
            name: editZoneObj.zonename,
            origid: editZoneObj.zoneid,
          };
        }
        return returnZoneObj;
      });
      return updateObject(state, {
        editError: "",
        isEditLoading: false,
        zoneData: [...updateZoneList],
      });
    case `${actionTypes.EDIT_ZONE}_FAILED`:
      return updateObject(state, {
        editError: action.payload,
        isEditLoading: false,
      });
    case `${actionTypes.EDIT_SECTION}`:
      return updateObject(state, { editError: "", isEditLoading: true });
    case `${actionTypes.EDIT_SECTION}_SUCCESS`:
      const editSectionObj = action.payload;
      const activeZoneid = editSectionObj.sectionid.split(".")[0];
      const updateSectionList = state.zoneData.map((zoneObj) => {
        let returnObj = zoneObj;
        if (zoneObj.origid === activeZoneid) {
          returnObj["recentModifiedOn"] = editSectionObj.lastmodifiedon;
          const updatedSection = zoneObj.sections.map((sectionObj) => {
            let returnSecObj = sectionObj;
            if (sectionObj.origid === editSectionObj.sectionid) {
              returnSecObj = {
                ...returnSecObj,
                ...editSectionObj,
                name: editSectionObj.sectionname,
                origid: editSectionObj.sectionid,
              };
            }
            return returnSecObj;
          });
          returnObj.sections = updatedSection;
        }
        return returnObj;
      });

      return updateObject(state, {
        editError: "",
        isEditLoading: false,
        zoneData: [...updateSectionList],
      });
    case `${actionTypes.EDIT_SECTION}_FAILED`:
      return updateObject(state, {
        editError: action.payload,
        isEditLoading: false,
      });

    case `${actionTypes.RENAME_TEMPLATE}_SUCCESS`: {
      const { payload } = action;
      let orgTemplatesImport = [...state.orgTemplates];
      let studyTemplatesImport = [...state.studyTemplates];
      switch (payload.templatecategory) {
        case "Organization template": {
          const findOrgIndex = !_.isEmpty(orgTemplatesImport)
            ? _.findIndex(orgTemplatesImport, {
              templateid: payload.templateid,
            })
            : 0;
          orgTemplatesImport[findOrgIndex] = payload;
          break;
        }
        case "Study template": {
          const findIndex = !_.isEmpty(studyTemplatesImport)
            ? _.findIndex(studyTemplatesImport, {
              templateid: payload.templateid,
            })
            : 0;
          studyTemplatesImport[findIndex] = payload;
          break;
        }
        default:
          break;
      }
      return updateObject(state, {
        orgTemplates: orgTemplatesImport,
        studyTemplates: studyTemplatesImport,
      });
    }
    case actionTypes.ADD_NEW_ARTIFACT:
      return updateObject(state, {
        isEditLoading: true,
        isArtifactEditSuccess: false,
        artifactFail: "",
      });
    case `${actionTypes.ADD_NEW_ARTIFACT}_FAILED`:
      const errorText = action.payload;
      return updateObject(state, {
        artifactFail: errorText,
        isEditLoading: false,
        isArtifactEditSuccess: false,
      });
    case `${actionTypes.ADD_NEW_ARTIFACT}_SUCCESS`:
      const { zoneList, zoneId, sectionId, artifactId } = action.payload;
      let updatedZone = zoneList;
      updatedZone["recentModifiedOn"] = action.payload.lastmodifiedon;
      updatedZone["sections"] = zoneList["sections"].map((secObj) => {
        let returnSecObj = secObj;
        if (returnSecObj.origid === sectionId) {
          returnSecObj["recentModifiedOn"] = action.payload.lastmodifiedon;
        }
        return returnSecObj;
      });
      let addedZoneList = [...state.zoneData];
      const findZone = _.find(addedZoneList, { origid: zoneId });
      if (!_.isEmpty(findZone)) {
        addedZoneList = addedZoneList.map((obj) => {
          let returnZoneObj = obj;
          if (obj.origid === zoneId) {
            returnZoneObj = {
              ...updatedZone,
            };
          } else {
          }
          return returnZoneObj;
        });
      } else {
        addedZoneList.push(updatedZone);
      }
      return updateObject(state, {
        zoneData: [...addedZoneList],
        isEditLoading: false,
        isArtifactEditSuccess: true,
        artifactFail: "",
      });
    case `${actionTypes.DELETE}`:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.DELETE}_SUCCESS`:
      const updatedDeleteZone = action.payload.zonedata;
      const activeDeleteZoneId = action.payload.zoneId;
      const activeDeleteSectionId = action.payload.sectionId;
      const activeDeleteArtifactId = action.payload.artifactId;
      let updatedDeleteZonelist = [...state.zoneData];
      if (_.isEmpty(updatedDeleteZone)) {
        updatedDeleteZonelist = updatedDeleteZonelist.filter(
          (obj) => obj.origid !== activeDeleteZoneId
        );
      } else {
        updatedDeleteZonelist = updatedDeleteZonelist.map((obj) => {
          let returnZoneObj = obj;
          if (returnZoneObj.origid === activeDeleteZoneId) {
            returnZoneObj = {
              recentModifiedOn: action.payload.lastmodifiedon,
              ...updatedDeleteZone,
            };
          }
          return returnZoneObj;
        });

        updatedDeleteZonelist = updatedDeleteZonelist.map((obj) => {
          let returnZoneObj = obj;
          if (returnZoneObj.origid === activeDeleteZoneId) {
            let updatedDeleteSectionlist = returnZoneObj.sections.map(
              (sectionObj) => {
                let returnSectionsObj = sectionObj;

                if (returnSectionsObj.origid === activeDeleteSectionId) {
                  let updatedDeleteArtifactlist =
                    returnSectionsObj.artifacts.map((artifactObj) => {
                      let returnArtifactObj = artifactObj;
                      if (returnArtifactObj.origid === activeDeleteArtifactId) {
                        returnArtifactObj = {
                          ...returnArtifactObj,
                          recentModifiedOn: action.payload.lastmodifiedon,
                        };
                      }
                      return returnArtifactObj;
                    });

                  returnSectionsObj = {
                    ...returnSectionsObj,
                    recentModifiedOn: action.payload.lastmodifiedon,
                    artifacts: updatedDeleteArtifactlist,
                  };
                }
                returnZoneObj.sections = returnSectionsObj;
                return returnSectionsObj;
              }
            );

            returnZoneObj = {
              ...returnZoneObj,
              recentModifiedOn: action.payload.lastmodifiedon,
              sections: updatedDeleteSectionlist,
            };
          }
          return returnZoneObj;
        });
      }

      return updateObject(state, {
        isEditLoading: false,
        zoneData: updatedDeleteZonelist,
      });

    case `${actionTypes.DELETE}_FAILED`:
      return updateObject(state, { isEditLoading: false });

    case actionTypes.GET_MILESTONE_LIST:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_LIST}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_LIST}_SUCCESS`:
      const { milestoneresult } = action.payload || [];
      const updatedMilestoneList = milestoneresult.map((obj) => ({
        ...obj,
        text: obj.name,
      }));
      return updateObject(state, {
        mileStoneList: updatedMilestoneList,
      });
    case actionTypes.GET_DATING_CONVENTION_LIST:
      return updateObject(state, {});
    case `${actionTypes.GET_DATING_CONVENTION_LIST}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.GET_DATING_CONVENTION_LIST}_SUCCESS`:
      let updateDatingConventionList = action.payload.datingconvention || [];
      updateDatingConventionList = updateDatingConventionList.map((list) => ({
        id: list,
        name: list,
      }));
      updateDatingConventionList.push({ id: "", name: "New" });
      return updateObject(state, {
        datingConventionList: updateDatingConventionList,
        rawDatingConventionList: action.payload.datingconvention || [],
      });

    case actionTypes.GET_TMF_STRUCTURE_CHANGES:
      return updateObject(state, { isChangeLoading: true });
    case `${actionTypes.GET_TMF_STRUCTURE_CHANGES}_FAILED`:
      return updateObject(state, { isChangeLoading: false });
    case `${actionTypes.GET_TMF_STRUCTURE_CHANGES}_SUCCESS`:
      const { changeditems, ischangefound, tmfcounts } = action.payload;
      const checkMilestoneChanged = _.find(changeditems, {
        category: "MILESTONE",
      });
      let updatedTMFStructure = state.tmfStructureData;
      if (tmfcounts) {
        updatedTMFStructure = {
          ...updatedTMFStructure,
          ...tmfcounts,
        };
      }
      return updateObject(state, {
        changeditems: changeditems || [],
        isMilestoneChanged: !_.isEmpty(checkMilestoneChanged),
        ischangefound,
        isChangeLoading: false,
        tmfStructureData: updatedTMFStructure,
      });
    case `${actionTypes.REVERT_STRUCTURE_CHANGES}`:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.REVERT_STRUCTURE_CHANGES}_SUCCESS`:
      return updateObject(state, {
        isEditLoading: false,
        revertChange: action.payload,
      });
    case `${actionTypes.REVERT_STRUCTURE_CHANGES}_FAILED`:
      return updateObject(state, { isEditLoading: false });
    case `${actionTypes.APPLY_STRUCTURE_CHANGES}`:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.APPLY_STRUCTURE_CHANGES}_SUCCESS`:
      return updateObject(state, {
        isEditLoading: false,
        applyChange: action.payload,
      });
    case `${actionTypes.APPLY_STRUCTURE_CHANGES}_FAILED`:
      return updateObject(state, { isEditLoading: false });
    case actionTypes.GET_ENABLING_MILESTONE_STATUS:
      return updateObject(state, { isStatusLoading: true });
    case `${actionTypes.GET_ENABLING_MILESTONE_STATUS}_FAILED`:
      return updateObject(state, { isStatusLoading: false });
    case `${actionTypes.GET_ENABLING_MILESTONE_STATUS}_SUCCESS`:
      const response = action.payload;
      return updateObject(state, {
        isStatusLoading: false,
        settings: {
          isMilestoneEnabled: response.ismilestoneenabled,
          islockdoconapproveenabled: response.islockdoconapproveenabled,
          isreasonforunlockdocenabled: response.isreasonforunlockdocenabled,
          isshareddropzoneenabled: response.isshareddropzoneenabled,
          isprivatedropzoneenabled: response.isprivatedropzoneenabled,
          istmflevelenabled: response.istmflevelenabled,
          isaggridincluded: response.isaggridincluded,
          displaytemplate: response.displaytemplate,
          downloadtemplate: response.downloadtemplate,
          archivetemplate: response.archivetemplate,
          showstructurenumber: response.showstructurenumber
        }
      });
    case actionTypes.UPDATING_ENABLING_MILESTONE_STATUS:
      return updateObject(state, { isEditLoading: true, isStatusLoading: true });
    case `${actionTypes.UPDATING_ENABLING_MILESTONE_STATUS}_FAILED`:
      return updateObject(state, { isEditLoading: false, isStatusLoading: false });
    case `${actionTypes.UPDATING_ENABLING_MILESTONE_STATUS}_SUCCESS`:
      const updatedResponse = action.payload;
      return updateObject(state, {
        settings: {
          isMilestoneEnabled: updatedResponse.ismilestoneenabled,
          islockdoconapproveenabled: updatedResponse.islockdoconapproveenabled,
          isreasonforunlockdocenabled: updatedResponse.isreasonforunlockdocenabled,
          isshareddropzoneenabled: updatedResponse.isshareddropzoneenabled,
          isprivatedropzoneenabled: updatedResponse.isprivatedropzoneenabled,
          istmflevelenabled: updatedResponse.istmflevelenabled,
          isaggridincluded: updatedResponse.isaggridincluded,
          displaytemplate: updatedResponse.displaytemplate,
          downloadtemplate: updatedResponse.downloadtemplate,
          archivetemplate: updatedResponse.archivetemplate
        },
        isEditLoading: false,
        isStatusLoading: false
      });
    case actionTypes.TEMPLATE_FILE_SCREENING_STATUS:
      return updateObject(state, {});
    case `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_FAILED`:
      return updateObject(state, { fileScreeningStatus: action.payload });
    case `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_SUCCESS`:
      return updateObject(state, {
        fileScreeningStatus: action.payload,
        // selectedLoading: state.isCancelBeforeSuccess,
      });
    case `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_CANCEL`:
      return updateObject(state, {
        // selectedLoading: true
      });
    case `${actionTypes.IS_CANCEL_TEMPLATE}`:
      return updateObject(state, {
        isTemplateCancel: action.payload
      });
    case `${actionTypes.TEMPLATE_FILE_SCREENING_STATUS}_CANCEL_BEFORE_SUCCESS`:
      return updateObject(state, {
        // selectedLoading: true,
        isCancelBeforeSuccess: action.payload,
      });
    case actionTypes.GET_MILESTONE_INFO_LIST:
      return updateObject(state, {
        viewMilestoneListLoading: true,
        isViewMilestoneList: true,
      });
    case `${actionTypes.GET_MILESTONE_INFO_LIST}_FAILED`:
      return updateObject(state, { viewMilestoneListLoading: false });
    case `${actionTypes.GET_MILESTONE_INFO_LIST}_SUCCESS`:
      let updatedMileStoneInfoList = action.payload || [];
      updatedMileStoneInfoList = _.groupBy(updatedMileStoneInfoList, "group");
      return updateObject(state, {
        mileStoneInfoList: updatedMileStoneInfoList,
        viewMilestoneListLoading: false,
      });
    case actionTypes.CLOSE_VIEW_MILESTONE:
      return updateObject(state, { isViewMilestoneList: false });
    case actionTypes.GET_MILESTONE_COUNT:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_COUNT}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_COUNT}_SUCCESS`:
      const { milestoneCount } = action.payload || {};
      return updateObject(state, {
        milestoneCount: milestoneCount,
        isEditLoading: false,
      });
    case actionTypes.GET_MILESTONE:
      return updateObject(state, {
        isSingleMilestoneView: true,
      });
    case `${actionTypes.GET_MILESTONE}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE}_SUCCESS`:
      return updateObject(state, {
        mileStoneObjInfo: action.payload || {},
      });
    case actionTypes.GET_MILESTONE_ARTIFACT_COUNT:
      return updateObject(state, {
        isMilestoneViewLoading: true,
      });
    case `${actionTypes.GET_MILESTONE_ARTIFACT_COUNT}_FAILED`:
      return updateObject(state, {
        isMilestoneViewLoading: false,
      });
    case `${actionTypes.GET_MILESTONE_ARTIFACT_COUNT}_SUCCESS`:
      let artifactList = action.payload;
      let artifactCount = _.filter(artifactList, { isdeleted: false }).length;

      return updateObject(state, {
        artifactCount: artifactCount,
        artifactList: artifactList,
        isMilestoneViewLoading: false,
      });
    case actionTypes.CLOSE_SINGLE_MILESTONE_VIEW:
      return updateObject(state, {
        milestoneActionFail: "",
        mileStoneObjInfo: {},
        isSingleMilestoneView: false,
        errorFields: [],
      });
    case actionTypes.ENABLE_FORM_ADD_MILESTONE:
      return updateObject(state, { isSingleMilestoneView: true });
    case actionTypes.GET_MILESTONE_GROUP:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_GROUP}_FAILED`:
      return updateObject(state, {});
    case `${actionTypes.GET_MILESTONE_GROUP}_SUCCESS`:
      return updateObject(state, {
        milestoneGroup: action.payload || [],
      });
    case actionTypes.ADD_NEW_MILESTONE:
      return updateObject(state, {
        isEditLoading: true,
      });
    case `${actionTypes.ADD_NEW_MILESTONE}_FAILED`:
      let { message, existingfields, formErrorValues } = action.payload;
      return updateObject(state, {
        milestoneActionFail: message || "",
        isEditLoading: false,
        errorFields: existingfields,
        // formErrorValues: { ...formErrorValues },
      });
    case `${actionTypes.ADD_NEW_MILESTONE}_SUCCESS`:
      return updateObject(state, {
        mileStoneLastModified: action.payload.lastmodifiedon,
        isEditLoading: false,
        isSingleMilestoneView: false,
        milestoneActionFail: "",
        errorFields: [],
      });
    case actionTypes.EDIT_MILESTONE:
      return updateObject(state, {
        isEditLoading: true,
      });
    case `${actionTypes.EDIT_MILESTONE}_FAILED`:
      return updateObject(state, {
        milestoneActionFail: action.payload.message || "",
        isEditLoading: false,
        errorFields: [...action.payload.existingfields],
        // formErrorValues: { ...formErrorValues },
      });
    case `${actionTypes.EDIT_MILESTONE}_SUCCESS`:
      // let mileStoneInfoList = _.groupBy(action.payload.milestones, "group") || [];
      return updateObject(state, {
        mileStoneLastModified: action.payload.lastmodifiedon,
        isEditLoading: false,
        isSingleMilestoneView: false,
        mileStoneObjInfo: {},
        milestoneActionFail: "",
      });
    case actionTypes.CHANGE_MILESTONE_SEQUENCE:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.CHANGE_MILESTONE_SEQUENCE}_FAILED`:
      return updateObject(state, {
        milestoneActionFail: action.payload || "",
        isEditLoading: false,
      });
    case `${actionTypes.CHANGE_MILESTONE_SEQUENCE}_SUCCESS`:
      return updateObject(state, {
        mileStoneLastModified: action.payload.lastmodifiedon,
        isEditLoading: false,
      });
    case actionTypes.GET_MILESTONE_FILTER_LIST:
      const isFromArchive = action.payload;
      return updateObject(state, { isEditLoading: !_.isEmpty(isFromArchive) });
    case `${actionTypes.GET_MILESTONE_FILTER_LIST}_FAILED`:
      return updateObject(state, { isEditLoading: false });
    case `${actionTypes.GET_MILESTONE_FILTER_LIST}_SUCCESS`:
      let updateMilestoneFilterList = action.payload.milestoneresult || [];
      updateMilestoneFilterList = updateMilestoneFilterList.map((obj) => ({
        ...obj,
        text: obj.name,
      }));
      let milestoneGroupObj = _.groupBy(updateMilestoneFilterList, "group");
      let UpdatedMileStoneListWithGroup = [];
      state.milestoneGroup.map((group) => {
        const returnObj = {
          id: `${group}_milestoneGroup_`,
          name: group,
          text: group,
        };
        UpdatedMileStoneListWithGroup.push(returnObj);
        UpdatedMileStoneListWithGroup = [
          ...UpdatedMileStoneListWithGroup,
          ...(milestoneGroupObj[group] || []),
        ];
      });

      return updateObject(state, {
        mileStoneListWithGroup: UpdatedMileStoneListWithGroup,
        isEditLoading: false,
      });
    case actionTypes.SET_ETMF_LOCK:
      return updateObject(state, { isLockLoading: true });
    case `${actionTypes.SET_ETMF_LOCK}_SUCCESS`:
      return updateObject(state, { isEtmfLocked: true, isLockLoading: false, lockedon: action.payload.lockedon, lockedby: action.payload.lockedby });
    case `${actionTypes.SET_ETMF_LOCK}_FAILED`:
      return updateObject(state, {
        isLockLoading: false
      });
    case actionTypes.SET_ETMF_UNLOCK:
      return updateObject(state, { isLockLoading: true });
    case `${actionTypes.SET_ETMF_UNLOCK}_SUCCESS`:
      return updateObject(state, { isEtmfLocked: false, isLockLoading: false });
    case `${actionTypes.SET_ETMF_UNLOCK}_FAILED`:
      return updateObject(state, {
        isLockLoading: false
      });
    case actionTypes.DELETE_MILESTONE:
      return updateObject(state, { isEditLoading: true });
    case `${actionTypes.DELETE_MILESTONE}_FAILED`:
      return updateObject(state, {
        milestoneActionFail: action.payload || "",
        isEditLoading: false,
      });
    case `${actionTypes.DELETE_MILESTONE}_SUCCESS`:
      return updateObject(state, {
        mileStoneLastModified: action.payload.lastmodifiedon,
        mileStoneObjInfo: {},
        isEditLoading: false,
        isSingleMilestoneView: false,
      });

    case `${actionTypes.GET_AGGRID_DATA}_SUCCESS`:
      return updateObject(state, {
        agGridData: action.payload
      });

    case `${actionTypes.GET_SHARED_DOCUMENT}_SUCCESS`:
      return updateObject(state, {
        sharedDocumentLinks: action.payload
      });

    case `${actionTypes.GET_SHARED_DOCUMENT}_FAILED`:
      return updateObject(state, {
        sharedDocumentLinks: []
      });

    case `${actionTypes.GET_CUSTOMIZED_COLSTATE}_SUCCESS`:
      let customColState = (action.payload.userpreference === null || action.payload.userpreference === "{}") ? "{}" : action.payload.userpreference;
      let customFilState = (action.payload.filterpreference === null || action.payload.filterpreference === "{}") ? "{}" : action.payload.filterpreference;
      sessionStorage.removeItem("customizedColState");
      sessionStorage.removeItem("customizedFilterState");
      sessionStorage.setItem("customizedColState", customColState);
      sessionStorage.setItem("customizedFilterState", customFilState);
      sessionStorage.removeItem("tempCustomizedColState")
      sessionStorage.removeItem("tempCustomizedFilterState")
      sessionStorage.removeItem("tempDefaultColState")
      sessionStorage.removeItem("tempDefaultFilterState")
      return updateObject(state, {
        customizedColState: customColState,
        customizedFilterState: customFilState,
      });

    case actionTypes.COMING_FROM_PAGE:
      return updateObject(state, { editFrom: action.payload });
    case actionTypes.GET_COMPLETE_AUDIT_TRAIL:
      return updateObject(state, { isArchiveLoading: true });
    case actionTypes.SET_AGGRID_FROM:
      return updateObject(state, { agGridFrom: action.payload });
    case `${actionTypes.GET_COMPLETE_AUDIT_TRAIL}_FAILED`:
      return updateObject(state, { isArchiveLoading: false });
    case `${actionTypes.GET_COMPLETE_AUDIT_TRAIL}_SUCCESS`:
      return updateObject(state, {
        isArchiveLoading: !action.isFrom,
        latestArchiveInfo: action.payload,
      });
    case actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL:
      return updateObject(state, {
        isGeneratingAuditTrail: true,
        isCancelBeforeSuccess: false,
        isCancelLoading: false,
        generateAuditTrailInfo: { percentage: 0 },
      });
    case `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_FAILED`:
      return updateObject(state, { isGeneratingAuditTrail: false });
    case `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_SUCCESS`:
      const { percentage, status, statusmessage, latest } = action.payload;
      let updateLatestArchiveInfo = state.latestArchiveInfo;
      if (percentage === 100 && status === "Completed" && !_.isEmpty(latest)) {
        updateLatestArchiveInfo = latest;
      }
      return updateObject(state, {
        isGeneratingAuditTrail: !(
          percentage === 100 || ["Cancelled", "Failed"].includes(status)
        ),
        generateAuditTrailInfo: action.payload,
        latestArchiveInfo: updateLatestArchiveInfo,
        isCancelLoading: state.isCancelLoading
          ? !["Completed", "Cancelled", "Failed"].includes(status)
          : false,
        isArchiveLoading: false,
      });
    case `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_STATUS`:
      return updateObject(state, {});
    case `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_CANCEL`:
      return updateObject(state, {
        generateAuditTrailInfo: {
          percentage: state.generateAuditTrailInfo.percentage,
        },
        isCancelLoading: true,
      });
    case actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL:
      let downloadingFiles = state.downloadingFiles;
      downloadingFiles.push(action.payload.id);
      return updateObject(state, { downloadingFiles: downloadingFiles });
    case `${actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL}_FAILED`:
      const removeFailedFiles = state.downloadingFiles.filter(id => id !== action.id)
      return updateObject(state, { downloadingFiles: removeFailedFiles });
    case `${actionTypes.DOWNLOAD_COMPLETE_AUDIT_TRAIL}_SUCCESS`:
      const removeFiles = state.downloadingFiles.filter(id => id != action.payload);
      return updateObject(state, { downloadingFiles: removeFiles });
    case `${actionTypes.GENERATE_COMPLETE_AUDIT_TRAIL}_CANCEL_BEFORE_SUCCESS`:
      return updateObject(state, {
        isCancelBeforeSuccess: true,
        isCancelLoading: true,
      });
    case actionTypes.GET_DROP_ZONE_SETTING:
      return updateObject(state, { loading: true });
    case `${actionTypes.GET_DROP_ZONE_SETTING}_FAILED`:
      return updateObject(state, { loading: false });
    case `${actionTypes.GET_DROP_ZONE_SETTING}_SUCCESS`:
      const dropZoneSettings = action.payload;
      return updateObject(state, {
        sharedfilecount: dropZoneSettings.sharedfilecount,
        privatefilecount: dropZoneSettings.privatefilecount,
        loading: false
      });
    case actionTypes.ACTIVE_DROP_ZONE:
      return updateObject(state, { activeDropzone: action.payload });
    case actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS:
      return updateObject(state, {});
    case `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_FAILED`:
      return updateObject(state, { dropZoneFileScreeningStatus: action.payload });
    case `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_SUCCESS`:
      const screeningStatus = action.payload && action.payload.status;
      return updateObject(state, {
        dropZoneFileScreeningStatus: action.payload,
        // selectedLoading: state.isCancelBeforeSuccess,
      });
    case `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_CANCEL`:
      return updateObject(state, {
        // selectedLoading: true
      });
    case `${actionTypes.GET_DROP_ZONE_FILESCREENING_STATUS}_CANCEL_BEFORE_SUCCESS`:
      return updateObject(state, {
        // selectedLoading: true,
        isDropZoneCancelBeforeSuccess: action.payload,
      });
    case `${actionTypes.GET_DROP_ZONE_DATA}`:
      return updateObject(state, { isLoading: true, invalidDoc: [] });
    case `${actionTypes.GET_DROP_ZONE_DATA}_SUCCESS`:
      let dropZoneDocments = [];

      if (!_.isEmpty(state.progressDropZoneFiles)) {
        dropZoneDocments = [...state.progressDropZoneFiles, ...action.payload];
      } else {
        dropZoneDocments = [...action.payload];
      }
      return updateObject(state, {
        dropZoneDoc: dropZoneDocments,
        isLoading: false
      });
    case `${actionTypes.GET_DROP_ZONE_DATA}_FAILED`:
      return updateObject(state, { isLoading: false });
    case actionTypes.DO_DROP_ZONE_FILESCREENING_STATUS:
      let progressFileData = [action.payload.documentData, ...state.progressDropZoneFiles];
      progressFileData = progressFileData.sort(function (a, b) {
        if (a.filename < b.filename) { return -1; }
        if (a.filename > b.filename) { return 1; }
        return 0;
      });
      let getLocalstore = JSON.parse(localStore.getItem(action.payload.documentData.category));
      getLocalstore[action.payload.docIndex] = action.payload.documentData;
      localStore.setItem(action.payload.documentData.category, JSON.stringify(getLocalstore));
      if (action.payload.documentData.category === state.activeDropzone) {
        let fileData = [...state.dropZoneDoc];
        fileData[action.payload.docIndex] = action.payload.documentData;
        fileData = fileData.filter((data) => !_.isEmpty(data))
        return updateObject(state, { dropZoneDoc: fileData, progressDropZoneFiles: progressFileData });
      } else {
        return updateObject(state, { progressDropZoneFiles: progressFileData });
      }
    case actionTypes.SET_INVALID_FILE_ERROR:
      let invalidData = [...state.invalidDoc, action.payload.documentData];
      return updateObject(state, { invalidDoc: invalidData });
    case actionTypes.REMOVE_INVALID_DOC:
      let resetInvalidData = state.invalidDoc;
      resetInvalidData = resetInvalidData.filter((data, index) => index !== action.payload.index);
      return updateObject(state, { invalidDoc: resetInvalidData });
    case actionTypes.RESET_PROGRESS_FILE_DATA:
      let fileInfo = action.payload.fileInfo;
      let filterProgressDropZoneFile = state.progressDropZoneFiles.filter(p => p.guid === fileInfo.guid);
      let filterIndexDropZone = state.progressDropZoneFiles.indexOf(filterProgressDropZoneFile[0]);
      let filterProgressFile = state.dropZoneDoc.filter(p => p.guid === fileInfo.guid);
      let filterIndex = state.dropZoneDoc.indexOf(filterProgressFile[0]);
      let resetData = state.dropZoneDoc;
      let resetProgressSideData = state.progressDropZoneFiles;
      let getlocalstoreData = JSON.parse(localStore.getItem(fileInfo.category));
      let getlocalstoreIndex = getlocalstoreData.findIndex(file => file.guid === fileInfo.guid);

      switch (action.payload.status) {
        case "Upload In-Progress":
          resetData[filterIndex] = {
            ...resetData[filterIndex],
            uploadingProgress: (resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
          }
          resetProgressSideData[filterIndexDropZone] = {
            ...resetProgressSideData[filterIndexDropZone],
            uploadingProgress: (resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
          }
          getlocalstoreData[getlocalstoreIndex] = {
            ...getlocalstoreData[getlocalstoreIndex],
            uploadingProgress: (getlocalstoreData[getlocalstoreIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : getlocalstoreData[getlocalstoreIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
            createdon: getlocalstoreData[getlocalstoreIndex].createdon,
            createdby: getlocalstoreData[getlocalstoreIndex].createdby
          }
          localStore.setItem(fileInfo.category, JSON.stringify(getlocalstoreData));
          return updateObject(state, { dropZoneDoc: resetData, progressDropZoneFiles: resetProgressSideData });
        case "Scanning In-Progress":
          resetData[filterIndex] = {
            ...resetData[filterIndex],
            uploadingProgress: (resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
          }
          resetProgressSideData[filterIndexDropZone] = {
            ...resetProgressSideData[filterIndexDropZone],
            uploadingProgress: (resetProgressSideData[filterIndexDropZone].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : resetProgressSideData[filterIndexDropZone].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
          }
          getlocalstoreData[getlocalstoreIndex] = {
            ...getlocalstoreData[getlocalstoreIndex],
            uploadingProgress: (getlocalstoreData[getlocalstoreIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]) >= 100) ? 100 : resetData[filterIndex].uploadingProgress + UtilsHelper.randomNumber([4, 5, 6]),
            createdon: getlocalstoreData[getlocalstoreIndex].createdon,
            createdby: getlocalstoreData[getlocalstoreIndex].createdby
          }
          localStore.setItem(fileInfo.category, JSON.stringify(getlocalstoreData));
          return updateObject(state, { dropZoneDoc: resetData, progressDropZoneFiles: resetProgressSideData });
        case "Import In-Progress":
          resetData[filterIndex] = {
            ...resetData[filterIndex],
            uploadingProgress: 100,
          }
          resetProgressSideData[filterIndexDropZone] = {
            ...resetProgressSideData[filterIndexDropZone],
            uploadingProgress: 100,
          }
          getlocalstoreData[getlocalstoreIndex] = {
            ...getlocalstoreData[getlocalstoreIndex],
            uploadingProgress: 100,
            createdon: getlocalstoreData[getlocalstoreIndex].createdon,
            createdby: getlocalstoreData[getlocalstoreIndex].createdby
          }
          localStore.setItem(fileInfo.category, JSON.stringify(getlocalstoreData));
          return updateObject(state, { dropZoneDoc: resetData, progressDropZoneFiles: resetProgressSideData });
        case "Imported":
          resetData[filterIndex] = {
            ...resetData[filterIndex],
            isUploading: false,
          }
          getlocalstoreData[getlocalstoreIndex] = {
            ...getlocalstoreData[getlocalstoreIndex],
            isUploading: false,
            createdon: getlocalstoreData[getlocalstoreIndex].createdon,
            createdby: getlocalstoreData[getlocalstoreIndex].createdby
          }
          localStore.setItem(fileInfo.category, JSON.stringify(getlocalstoreData));
          return updateObject(state, { dropZoneDoc: resetData, previousDropZoneDoc: resetData });
        case "cancelled":
          resetData = resetData.filter(data => data.guid !== fileInfo.guid);
          resetProgressSideData = resetProgressSideData.filter(data => data.guid !== fileInfo.guid);
          resetData = resetData.filter(data => data.guid !== fileInfo.guid);
          return updateObject(state, { dropZoneDoc: resetData, progressDropZoneFiles: resetProgressSideData });

      }
    case `${actionTypes.DO_SAVE_TMF_DROP_ZONE}_SUCCESS`:
      let resetDropZoneDocument = [...state.dropZoneDoc];
      let progressFiles = [...state.progressDropZoneFiles];
      progressFiles = progressFiles.filter(data => data.guid !== action.payload.fileguid);
      let findIndex = resetDropZoneDocument.findIndex(data => data.guid === action.payload.fileguid);
      resetDropZoneDocument[findIndex] = action.payload;
      return updateObject(state, { dropZoneDoc: resetDropZoneDocument, progressDropZoneFiles: progressFiles });
    case actionTypes.REMOVE_DELETE_DOC:
      let removeDoc = [...state.dropZoneDoc];
      let findDeletedDocIndex = removeDoc.filter(data => data.tmfdropzoneid !== action.payload.id);
      return updateObject(state, { dropZoneDoc: findDeletedDocIndex });
    case actionTypes.SET_DROP_ITEMS:
      return updateObject(state, { dropZoneDoc: [...action.payload] });
    case actionTypes.GET_LOCK_STATUS:
      return updateObject(state, { isLockLoading: true });
    case `${actionTypes.GET_LOCK_STATUS}_FAILED`:
      return updateObject(state, { isLockLoading: false });
    case `${actionTypes.GET_LOCK_STATUS}_SUCCESS`:
      return updateObject(state, { lockStatus: action.payload.etmfstatus, lockedon: action.payload.lockedon, lockedby: action.payload.lockedby, isEtmfLocked: action.payload.isetmflocked, isLockLoading: false });
    case `${actionTypes.GET_DOCU_TEMPLATE_NAMES}_SUCCESS`:
      return updateObject(state, { documentTemplateNames: action.payload });
    case actionTypes.CLONE_DOC_NEW_VERSION:
      return updateObject(state, {
        isNewDocVersionCreated: true,
        cloneLoading: true
      });
    case `${actionTypes.CLONE_DOC_NEW_VERSION}_FAILED`:
      return updateObject(state, {
        isNewDocVersionCreated: false,
        cloneLoading: false
      });
    case `${actionTypes.CLONE_DOC_NEW_VERSION}_SUCCESS`:
      return updateObject(state, {
        isNewDocVersionCreated: true,
        cloneLoading: false
      });
    case `${actionTypes.SET_ACTIVE_DOCID}`:
      return updateObject(state, {
        activeDocID: action.payload
      });
    case `${actionTypes.CLOSE_DOC_NEW_VERSION}`:
      return updateObject(state, {
        isNewDocVersionCreated: false,
      });
    default:
      return state;
  }
};
